<template>
	<swipe
		v-if="ready"
		class="gaucher-app"
		:class="[
			{ 'is-blurred': overlay },
			{ 'is-ipad': isIpadFlag }
		]"
		:data-flow="flowId"
		:data-endpoint="endpointId"
		:data-template="template"
		:data-debug="lyraDebug"
		id="app"
	>
		<!-- Main Router View -->
		<router-view
			class="gaucher-router-view"
			:class="{ 'is-blurred': overlay }"
		/>

		<!-- Overlay -->
		<overlay-template />

		<!-- Footer Bar -->
		<footer-bar v-if="footerReady" />

		<!-- Utils message -->
		<!-- Dummy Comment -->
		<div class="gau-utils">
			<grid />
		</div>
	</swipe>
</template>

<script>
	import FooterBar from '@ui/components/polymers/FooterBar';
	import OverlayTemplate from '@ui/components/templates/OverlayTemplate';
	import Grid from '@core/grid/Grid';
	import Swipe from '@core/vue-modules/Swipe';

	import { isDevEnvironment, isIpad, appContentUpdate, appContentUpdateElm } from '@core/utils';
	import { getAppManagerEndpoint } from '@core/lyra-data';

	export default {
		name: 'App',
		components: {
			FooterBar,
			Grid,
			Swipe,
			OverlayTemplate,
		},
		data () {
			return {
				appManager: [],
				assets: [],
				citations: [],
				ready: false,
				footerReady: false,
				template: '',
				isIpadFlag: '',
			}
		},
		computed: {
			flowId () {
				return this.$route.params.flowId;
			},
			endpointId () {
				return this.$route.params.endpointId;
			},
			lyraDebug () {
				return this.$store.getters.get_debug;
			},
			overlay () {
				return this.$store.getters.get_overlay;
			}
		},
		watch: {
			$route () {
				this.evaluateTemplate();
				this.debugLyra();

				document.dispatchEvent(new Event('x-app-state-loaded'));
			}
		},
		async beforeMount () {
			await this.initialiseLyraData();
			this.evaluateTemplate();
		},
		mounted () {
			// Debugging
			if (isDevEnvironment()) {
				window.huumunGaucher = this;
			}

			this.debugLyra();
			this.isIpadFlag = isIpad();

			document.dispatchEvent(new Event('x-app-loaded'));
			window.appContentUpdate = appContentUpdate;
			window.appContentUpdateElm = appContentUpdateElm;

		},
		methods: {
			/**
			 * Inititalise Lyra Data into Vuex Store
			 */
			async initialiseLyraData () {
				// App Manager Data
				const response = await this.$lyra.appManager();
				if (response.success) {
					this.appManager = response.data;
					this.$store.dispatch('axn_updateAppManager', this.appManager);
				}

				// Assets Data
				const assetsResponse = await this.$lyra.assetsAll();
				if (assetsResponse && assetsResponse.length > 0) {
					this.assets = assetsResponse;
					this.$store.dispatch('axn_updateAssets', this.assets);
				}

				// Citations Data
				const citationsResponse = await this.$lyra.citationsAll();
				if (citationsResponse && citationsResponse.length > 0) {
					this.citations = citationsResponse;
					this.$store.dispatch('axn_updateCitations', this.citations);
				}

				this.ready = true;
				this.footerReady = true;
			},

			/**
			 * Evaluate Slide template
			 */
			evaluateTemplate () {
				const obj = getAppManagerEndpoint(this.endpointId);
				this.template = obj.template;
			},

			/**
			 * Method for debugging LYRA
			 */
			debugLyra () {
				const { query } = this.$route;
				if (query && query.debug) {
					this.$store.dispatch('axn_updateDebugging', query.debug);
					return;
				}

				this.$store.dispatch('axn_updateDebugging', false);
			},
		}
	};
</script>

