<template>
	<div class="gaucher-bespoke-430_00_00">
		<div
            class="gaucher-bespoke-430_00_00__content"
            v-html="$ly('430_00_00.copy')"
        >
		</div>
	</div>
</template>

<script>

	export default {
        name: 'Bespoke430_00_00',
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-430_00_00 {
		height: 100%;
		display: flex;
        justify-content: center;
        margin-top: 130px;

		&__content {
            display: flex;
            width: 100%;
            justify-content: space-between;
            ::v-deep .info-box {
                background: $white;
                width: 286px;
                height: 369px;
                padding: 17px;
                text-align: center;
                &--image {
                    margin: 0 auto;
                }
                &--image.one {
                    background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/svg/80_infographic.svg');
                    width: 80px;
                    height: 87px;
                    background-size: contain;
                    margin-bottom: 16px;
                }
                &--image.two {
                    background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/svg/calendar_infographic.svg');
                    width: 77px;
                    height: 90px;
                    background-size: contain;
                    margin-top: 6px;
                    margin-bottom: 29px;
                }
                &--image.three {
                    background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/svg/86_infographic.svg');
                    width: 148px;
                    height: 61px;
                    background-size: contain;
                    margin: 24px auto;
                }
            }
            ::v-deep .info-box--copy {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 20px;
            }
            ::v-deep .info-box--highlite {
                font-size: 16px;
                line-height: 26px;
                font-weight: bold;
            }
            ::v-deep .info-box .text-red {
                color: $red;
            }
            ::v-deep .info-box .text-larger {
                font-size: 30px;
            }
        }
	}
</style>
