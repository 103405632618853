/**
 * Return client LYRA object exposed on windows
 */
function clientLyraObject () {
	return window.lyraClient || {};
}

/**
 * Returns client localisation
 */
function clientLocalisation () {
	const { localisation } = clientLyraObject();
	return localisation || '-';
}

/**
 * Return client mode
 */
function clientMode () {
	const { mode } = clientLyraObject();
	return mode || { mode: 'production' };
}

/**
 * Return client database
 */
function clientDatabase () {
	const { database } = clientLyraObject();
	return database || {};
}

/**
 * Return Client deploy route
 */
function clientDeployRoute () {
	const { deploy } = clientLyraObject();
	return deploy || 'json';
}

/**
 * Export
 */
module.exports.clientLyraObject = clientLyraObject;
module.exports.clientLocalisation = clientLocalisation;
module.exports.clientMode = clientMode;
module.exports.clientDatabase = clientDatabase;
module.exports.clientDeployRoute = clientDeployRoute;