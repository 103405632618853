/**
 * Assets functions
 *
 * Author: Sahil David
 */

const { apiRequest, noKeyFound } = require('../utils');
const { clientLocalisation } = require('../helpers');
const { cachedData } = require('../cached/utils');
const { assetAPI, assetsAllAPI } = require('../../config/endpoints');

/**
 * Returns asset value based on the key
 * @param {Object} params - connection params
 * @param {String} key - asset key to get value for
 */
async function $lyraAsset(params, key) {
	if (!key) { return noKeyFound(key); }

	const cacheResponse = await cachedData('lyraAsset', { key });
	if (cacheResponse.exit) {
		return cacheResponse.success ? cacheResponse.data : noKeyFound(key);
	}

	const url = assetAPI({
		':localisation': clientLocalisation(),
		':assetKey': key,
	}, { domain: params.domain });

	const _response = await apiRequest(url, params);
	return _response.success ? _response.data : noKeyFound(key);
}

/**
 * Checks if the asset key exist
 * @param {Object} params - connection params
 * @param {String} key - asset key to get value for
 */
async function $lyraAssetExist(params, key) {
	if (!key) { return false; }

	const cacheResponse = await cachedData('lyraAsset', { key });
	if (cacheResponse.exit) {
		return cacheResponse.success ? true : false;
	}

	const url = assetAPI({
		':localisation': clientLocalisation(),
		':assetKey': key,
	}, { domain: params.domain });

	const _response = await apiRequest(url, params);
	return _response.success;
}

/**
 * Returns all assets value
 * @param {Object} params - connection params
 */
async function $lyraAssetsAll(params) {
	const cacheResponse = await cachedData('lyraAssetsAll');
	if (cacheResponse.exit) {
		return cacheResponse.success ? cacheResponse.data : [];
	}

	const url = assetsAllAPI({
		':localisation': clientLocalisation(),
	}, { domain: params.domain });

	const _response = await apiRequest(url, params);
	return _response.success ? _response.data : [];
}

/**
 * Export Functions
 */
module.exports.$lyraAsset = $lyraAsset;
module.exports.$lyraAssetExist = $lyraAssetExist;
module.exports.$lyraAssetsAll = $lyraAssetsAll;
