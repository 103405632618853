<template>
	<div class="gaucher-bespoke-420_00_00">
		<div class="gaucher-bespoke-420_00_00__content">
            <div class="gaucher-bespoke-420_00_00__content-left">
                <img :src="require('../../../../design-system/assets/../shared/core/media/../shared/core/img/content/420_patient-diagram.png')" />
            </div>
            <div class="gaucher-bespoke-420_00_00__content-right">
                <p>General symptoms:<sup>2-4</sup></p>
                <ul>
                    <li>Fatigue</li>
                    <li>Easy bruising/bleeding</li>
                    <li>Menorrhagia</li>
                    <li>Decreased appetite</li>
                    <li>Abdominal pain</li>
                </ul>
            </div>
		</div>
	</div>
</template>

<script>
	export default {
        name: 'Bespoke420_00_00',
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-420_00_00 {
		height: 100%;
        margin-top: 40px;

        &__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            &-left {
                width: 55%;
                margin-right: 20px;
            }
            &-right {
                width: 35%;
                background-color: $white;
                padding: 70px 26px;
                margin-top: 60px;

                p {
                    margin-bottom: 20px;
                }

                ul {
                    padding-left: 40px;
                    li {
                        list-style-type: disc;
                        padding-left: 10px;
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
	}
</style>