import { sortArrayOfObjects } from '../utils';
import store from '../boot/store';

/**
 * Return citations array of objects for the given `string[]` citations.
 * @param endpointCitations - `string[]` citations
 */
export function getCitations (endpointCitations) {
    const citations = (store.getters.get_citations || []);
    const citationsActive = [];

    endpointCitations.forEach((citation) => {
        const citationActive = citations.find((_) => _.key === citation);

        if (citationActive) {
            citationsActive.push(citationActive);
        } else {
            console.error(`Couldn't find citation ${citation}`);
        }
    });

    return citationsActive;
}

/**
 * Return references `any[]` for the given citations object.
 * @param endpointCitations - `string[]` citations
 * @param sort - `false` by default. true if citations should be sorted alphabetically.
 */
export function getReferences (endpointCitations, sort = false) {
    const citations = getCitations(endpointCitations);

    // References
    const references = citations.filter((_) => _.type === 'References');

    if (sort) {
        return sortArrayOfObjects(references, 'key');
    }
    return references;
}

/**
 * Return abbreviations `any[]` for the given citations object.
 * @param endpointCitations - `string[]` citations
 * @param sort - `true` by default. true if citations should be sorted alphabetically.
 */
export function getAbbreviations (endpointCitations, sort = true) {
    const citations = getCitations(endpointCitations);

    // References
    const abbreviations = citations.filter((_) => _.type === 'Abbreviations');

    if (sort) {
        return sortArrayOfObjects(abbreviations, 'key');
    }
    return abbreviations;
}

/**
 * Return footnotes `any[]` for the given citations object.
 * @param endpointCitations - `string[]` citations
 * @param sort - `true` by default. true if citations should be sorted alphabetically.
 */
export function getFootnotes (endpointCitations, sort = true) {
    const citations = getCitations(endpointCitations);

    // References
    const footnotes = citations.filter((_) => _.type === 'Footnotes');

    if (sort) {
        return sortArrayOfObjects(footnotes, 'key');
    }
    return footnotes;
}
