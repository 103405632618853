<template>
	<div class="gaucher-ctacard">
        <p
            class="gaucher-ctacard--text"
            :data-content-key="`ctacard-${textTransform}`"
            v-html="text"
        ></p>
        <img src="../../../design-system/assets/../shared/core/media/../shared/core/img/svg/red-nav-arrow.svg" />
    </div>
</template>

<script>
	export default {
        name: 'CTACard',
		props: {
			text: {
				type: String,
				required: true,
			}
		},
        computed: {
            textTransform () {
                return this.text.toLowerCase().replaceAll(' ', '-')
            }
        }
	};
</script>

<style lang="scss" scoped>
	.gaucher-ctacard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
        &--text {
            @include FontMedium();
            color: #565656;
            font-size: 30px;
            line-height: 36px;
            margin-left: 37px;
        }
        & img {
            margin-right: 30px;
        }
	}
</style>