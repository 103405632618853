<template>
	<div class="gaucher-bespoke-320_00_00">
		<div class="gaucher-bespoke-320_00_00__content">
            <div class="left-col">

            </div>
            <div class="right-col">
                <div class="right-col--cta-grid">
                    <div
                        v-for="(btn, index) in ctaButtons"
                        :key="index"
                        :data-overlay="overlay"
                        @click="overlayItemActive(btn)"
                    >
                        <CTA-button
                            :text="$ly(`320_00_00.cta${index + 1}`)"
                        />
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
    import CTAButton from '@ui/components/atoms/CTAButton';

	export default {
        name: 'Bespoke320_00_00',
        components: {
            CTAButton,
        },
        data () {
            return {
                ctaButtons: ['destruction', 'osteolytic', 'bone-pain', 'bone-crisis', 'deformity', 'avn', 'osteopenia'],
                overlay: '320_10_00',
            }
        },
        methods: {
            overlayItemActive (item) {
                this.$store.dispatch('axn_updateActiveItem', item);
            }
        }
	};

</script>

<style lang="scss" scoped>
// 320_00_00-skeleton.png
	.gaucher-bespoke-320_00_00 {
		height: 100%;
		display: flex;
		justify-content: center;

		&__content {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            .left-col {
                background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/content/320_00_00-skeleton.png');
                width: 215px;
                height: 427px;
                background-size: contain;
                margin-top: 6px;
                margin-bottom: 29px;
            }
            .right-col {
                padding-top: 76px;
                &--cta-grid {
                    display: grid;
                    width: 518px;
                    grid-template-columns: 250px 250px;
                    grid-template-rows: 64px 64px;
                    row-gap: 13px;
                    column-gap: 18px;
                }
            }
		}
	}
</style>
