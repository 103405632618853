/**
 * Returns if the current environment is development or not
 */
export function isDevEnvironment () {
	return (process.env.NODE_ENV === 'development') || location.host.includes('localhost');
}

/**
 * Returns if the the platform is iPad
 */
export function isIpad () {
	return navigator.platform.match(/iPad/i);
}

