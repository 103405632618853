<template>
	<div class="gaucher-bespoke-110_00_00">
		<div class="gaucher-bespoke-110_00_00__content">
			<div
				class="patient-case-cta patient-card1"
				@click="navigateTo({ endpointId:'150_00_00', flows: 'malignancy' })"
			>
				<div
					class="patient-case-cta--copy"
					v-html="$ly('110_00_00.patient.card1')">
				</div>
				<view-btn />
			</div>
			<div class="patient-case-cta patient-card2"
				@click="navigateTo({ endpointId:'120_00_00', flows: 'malignancy' })"
			>
				<div
					class="patient-case-cta--copy"
					v-html="$ly('110_00_00.patient.card2')">
				</div>
				<view-btn />
			</div>
			<div class="patient-case-cta patient-card3"
				@click="navigateTo({ endpointId:'130_00_00', flows: 'malignancy' })"
			>
				<div
					class="patient-case-cta--copy"
					v-html="$ly('110_00_00.patient.card3')">
				</div>
				<view-btn />
			</div>
			<div class="patient-case-cta patient-card4"
				@click="navigateTo({ endpointId:'140_00_00', flows: 'malignancy' })"
			>
				<div
					class="patient-case-cta--copy"
					v-html="$ly('110_00_00.patient.card4')">
				</div>
				<view-btn />
			</div>
		</div>
	</div>
</template>

<script>
	import ViewBtn from '@ui/components/atoms/ViewBtn';
	import { goToSlide } from '@core/utils';

	export default {
		name: 'Bespoke110_00_00',
		components: {
			ViewBtn,
		},
		methods: {
			/**
			 * Navigate to Slide
			 */
			navigateTo (obj) {
				const path = `/slide/${obj.flows}/${obj.endpointId}`;
				const slideId = `${obj.flows}_${obj.endpointId}`;

				goToSlide({ slideId }, () => {
					this.$router.push(path).catch(() => {
						/**
						 * Catch error here
						 */
					});
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-110_00_00 {
		height: 100%;
		display: flex;
		justify-content: center;

		&__content {
			display: grid;
			width: 796px;
			grid-template-columns: 388px 388px;
			grid-template-rows: 220px 220px;
			column-gap: 20px;
			row-gap: 34px;
			padding-top: 70px;

			.patient-card1 {
				background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/content/110_00_00-patient1.png');
			}

			.patient-card2 {
				background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/content/110_00_00-patient2.png');
			}

			.patient-card3 {
				background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/content/110_00_00-patient3.png');
			}

			.patient-card4 {
				background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/content/110_00_00-patient4.png');
			}

			.patient-case-cta {
				background-size: contain;
				background-repeat: no-repeat;
				box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

				::v-deep p {
					padding: 29px 0 0 35px;
					font-size: 40px;
					line-height: 50px;
					color: $white;
					@include FontMedium();
				}
				::v-deep &--btn {
					padding: 15px 40px;
					font-size: 13px;
					line-height: 16px;
					color: white;
					border: 1px solid $white;
					border-radius: 22px;
					display: inline-block;
					margin: 22px 0 0 35px;
					font-family: Avant-Demi;
					@include FontDemi();
				}
			}
		}
	}
</style>
