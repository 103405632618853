<template>
	<div>
		<!-- footer -->
		<div class="gaucher-footer">
			<div class="gaucher-footer__container">

				<!-- footer controls -->
				<div class="gaucher-footer__controls">

					<!-- menu icon -->
					<div
						class="gaucher-footer__control-item jobbag-menu"
						:class="{ 'is-active': activePanel === 'menu' }"
						@click="updateFooterPanel('menu')"
					>
						<svg-icon icon="menu" />
						<div
							class="gaucher-footer__control-item--text"
							v-html="$ly('footer.menu')"
						/>
					</div>

					<!-- references icon -->
					<div
						class="gaucher-footer__control-item jobbag-citations"
						:class="[
							{ 'is-disabled': !hasCitations },
							{ 'is-active': activePanel === 'citations' }
						]"
						@click="updateFooterPanel('citations')"
					>
						<svg-icon icon="references" />
						<div
							class="gaucher-footer__control-item--text"
							v-html="$ly('footer.references')"
						/>
					</div>

					<!-- pi icon -->
					<div
						class="gaucher-footer__control-item"
						@click="openPI('cerdelga')"
					>
						<svg-icon icon="pi" />
						<div
							class="gaucher-footer__control-item--text"
							v-html="$ly('footer.pi-cerdelga')"
						/>
					</div>
					<!-- pi icon -->
					<div
						class="gaucher-footer__control-item"
						@click="openPI('cerezyme')"
					>
						<svg-icon icon="pi" />
						<div
							class="gaucher-footer__control-item--text"
							v-html="$ly('footer.pi-cerezyme')"
						/>
					</div>
				</div>

				<!-- footer job code -->
				<div
					class="gaucher-footer__job-code"
					v-html="$ly('footer.job-code')"
				/>

				<!-- footer icon -->
				<div class="gaucher-footer__controls">
					<svg-icon icon="sanofi-logo" />
				</div>
			</div>
		</div>

		<!-- Nav Menu Panel -->
		<transition name="fade">
			<div
				v-if="activePanel.length > 0"
				class="gaucher-footer__panel"
			>
				<div class="gaucher-footer__panel--base" @click="updateFooterPanel()" />
				<div
					v-if="activePanel === 'menu'"
					class="gaucher-footer__panel--content gaucher-footer__panel--menu"
				>
					<footer-menu />
				</div>
				<div
					v-if="activePanel === 'citations'"
					class="gaucher-footer__panel--content gaucher-footer__panel--citations"
				>
					<footer-citations
						:citations="endpointCitations"
						@close="closeCitations"
					/>
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
	import SvgIcon from '@ui/components/atoms/SvgIcon';
	import FooterMenu from './FooterMenu';
	import FooterCitations from './FooterCitations';

	import { openPDF} from '@core/utils';

	export default {
		components: {
			SvgIcon,
			FooterMenu,
			FooterCitations,
		},
		data() {
			return {
				endpointCitations: []
			};
		},
		computed: {
			activePanel () {
				return this.$store.getters.get_activePanel;
			},
			overlay () {
				return this.$store.getters.get_overlay;
			},
			endpointId () {
				return this.overlay ? this.overlay : (this.$route.params.endpointId || '');
			},
			hasCitations () {
				return this.endpointCitations.length > 0;
			}
		},
		watch: {
			async $route () {
				this.updateFooterPanel();
				await this.getEndpointCitations();
			},
			async overlay () {
				this.updateFooterPanel();
				await this.getEndpointCitations();
			}
		},
		async mounted () {
			await this.getEndpointCitations();
		},
		methods: {
			/**
			 * Update Footer Panel
			 */
			updateFooterPanel (key = '') {
				const { activePanel } = this;
				if (activePanel !== key) {
					this.$store.dispatch('axn_updateActivePanel', key);
				} else {
					this.$store.dispatch('axn_updateActivePanel', '');
				}
			},
			/**
			 * Get citations assigned to the endpoint.
			 */
			async getEndpointCitations () {
				const { endpointId } = this;
				const tierId = this.overlay ? 'overlays' : 'endpoints';
				const _endpoint = await this.$lyra.endpoints({
					tierId,
					endpointId,
				});

				if (_endpoint.success) {
					const [endpoint] = _endpoint.data;
					this.endpointCitations = endpoint.citations;
				}
			},
			/**
			 * Close panel.
			 */
			closeCitations () {
				this.updateFooterPanel();
			},
			/**
			 * Open PI
			 */
			openPI (pdf) {
				openPDF(pdf);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.gaucher-footer {
		position: fixed;
		left: 0;
		bottom: 0;

		@include grid('padding-left', 1);
		@include grid('padding-right', 1);

		width: 100%;

		height: $footer-height;

		display: flex;
		align-items: center;
		justify-content: space-between;

		background: $white;
		border-top: 4px solid $red;

		z-index: $z-footer;


		&__container {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include containerIPadSize();
			@include desktop {
				padding: 0 3em;
			}
		}

		&__job-code {
			font-family: 'ITCAvantGardeStd-Demi';
			font-size: em(10);
			color: $grey-jb;
		}

		&__controls {
			display: flex;
		}

		&__control-item {
			text-align: center;
			margin-right: 2em;
			transition: all .3s ease;

			&--text {
				font-size: em(12);
				color: $grey-menu;
				font-family: 'ITCAvantGardeStd-Demi';

				::v-deep p {
					font-family: 'ITCAvantGardeStd-Demi';
					font-size: em(12);
				}
			}

			&.is-disabled {
				opacity: .4;
				pointer-events: none;
			}
		}

		&__panel {
			position: fixed;
			bottom: $footer-height;
			left: 0;
			width: 100%;
			height: calc(100% - #{$footer-height});

			background: $overlay-bg;
			z-index: $z-footer-panel;

			display: flex;
			align-items: flex-end;

			@include containerIPadSize();
			@include desktop {
				@include centerX();
			}

			&--base {
				position: fixed;
				width: 100%;
				height: 100%;
				z-index: $z-footer-panel-base;
			}

			&--content {
				position: relative;
				z-index: $z-footer-panel-content;
				left: 72px;
			}

			&--citations {
				left: em(32);
			}
		}
	}
</style>
