/**
 * Vue main rendering file. Initialise the boot sequence & Vue Instance
 */

import Vue from 'vue'
import App from './App.vue'

/**
 * Boot Sequence
 */
import router from '@core/boot/router';
import store from '@core/boot/store';

/**
 * Global Styling
 */
import './design-system/styles/core.scss';

import { localisationGuard, saveLocalisation } from '@core/localisation';
/**
 * Routing Guard
 */
 router.beforeEach((to, from, next) => {
    localisationGuard(to);
    next();
});
/**
 * Localisation reload
 */
 window.onbeforeunload = saveLocalisation;

/**
 * LYRA Config
 */
import lyraConfig from '../lyra.config';
const { vueLyra } = require('../huumun-lyra');

Vue.use(vueLyra, lyraConfig);

/**
 * Vue Instance Initialisation
 */
Vue.config.productionTip = false

/**
 * Vue Mixin
 */
import LyraMixin from '@core/lyra-data/mixin';
Vue.mixin(LyraMixin);

new Vue({
	render: h => h(App),
	router,
	store,
}).$mount('#app');
