<template>
	<div
		class="gaucher-icon"
		:class="`gaucher-icon__${modifier}`"
		v-html="require(`!html-loader!../../../design-system/assets/../shared/core/media/icons/${this.icon}.svg`)"
	/>
</template>

<script>
	export default {
		props: {
			icon: {
				type: String,
				required: true,
			},
			modifier: {
				type: String,
				default: 'default',
			}
		}
	};
</script>

<style lang="scss" scoped>
	.gaucher-icon {

	}
</style>
