<template>
	<div
		class="gaucher-template-area gaucher-col"
		:class="datum.class"
		:style="areaStyle"
	>
		<template-content-asset
			v-if="datum.value === 'asset'"
			:datum="datum"
		/>

		<template-content-content
			v-if="datum.value === 'content'"
			:datum="datum"
			:overlay="overlay"
		/>

		<template-content-image
			v-if="datum.value === 'image'"
			:datum="datum"
			:overlay="overlay"
		/>

		<template-content-bespoke
			v-if="datum.value === 'bespoke'"
			:datum="datum"
			:overlay="overlay"
		/>

		<template-content-svg
			v-if="datum.value === 'svg'"
			:datum="datum"
			:overlay="overlay"
		/>

		<div
			v-if="lyraDebug"
			class="gaucher-template-debug"
		>
			{{ datum.value }}
		</div>
	</div>
</template>

<script>
	import TemplateContentAsset from './TemplateContentAsset';
	import TemplateContentContent from './TemplateContentContent';
	import TemplateContentImage from './TemplateContentImage';
	import TemplateContentBespoke from './TemplateContentBespoke';
	import TemplateContentSvg from './TemplateContentSvg';

	export default {
		components: {
			TemplateContentAsset,
			TemplateContentContent,
			TemplateContentImage,
			TemplateContentBespoke,
			TemplateContentSvg,
		},
		props: {
			datum: {
				type: Object,
				required: true,
			},
			overlay: {
				type: String,
				default: '',
			}
		},
		data () {
			return {
				areaStyle: {},
			}
		},
		computed: {
			lyraDebug () {
				return this.$store.getters.get_debug;
			}
		},
		beforeMount () {
			const { datum } = this;
			if (datum.width) {
				this.areaStyle.width = datum.width;
			}
		}
	};
</script>
