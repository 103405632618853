<template>
	<div class="gaucher-bespoke-320_10_00">
		<div 
			class="gaucher-bespoke-320_10_00__content overlay"
			:class="{ 'is-active': getActiveItem ===  overlay.item }"
			v-for="(overlay, index) in overlays"
			:key="index"
		>
            <h1 
				v-html="$ly(`320_10_00.${overlay.item}.title`)"
				class="overlay__title"
			/>
			<div
				v-html="$ly(`320_10_00.${overlay.item}.subtitle`)"
				class="overlay__subtitle"
			/>
			<div
				class="overlay__images"
				:class="`overlay__images--${overlay.item}`"
				v-if="overlay.images.length > 0"
			>
				<div 
					class="overlay__images-container"
					v-for="(img, index) in overlay.images"
					:key="index"
				>
					<img :src="require(`../../../../design-system/assets/../shared/core/media/../shared/core/img/overlay/${img}.png`)" />
					<div 
						class="overlay__images-caption"
						v-html="$ly(`320_10_00.${overlay.item}.img${index + 1}.caption`)"
					/>
				</div>
			</div>
			<div 
				class="overlay__text"
				:class="`overlay__text--${overlay.item}`"
			>
				<div
					v-html="$ly(`320_10_00.${overlay.item}.copy`)"
					class="overlay__text-copy"
				/>
			</div>
		</div>
		<div class="footer">
			<div class="footer__nav">
				<div
					v-for="(overlay, index) in overlays"
					:key="overlay.item"
					class="footer__nav-item"
					:class="itemNavClass(index)"
					v-html="$ly(`320_10_00.${overlay.item}.nav_name`)"
				/>
			</div>
			<div
				class="footer__arrow footer__arrow--prev"
				@click="getItemPrev (getActiveIndex)"
			/>
			<div class="footer__count">
				{{ getActiveIndex + 1 }}/{{ overlays.length }}
			</div>
			<div
				class="footer__arrow footer__arrow--next"
				@click="getItemNext (getActiveIndex)"
			/>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Bespoke320_10_00',
		data () {
			return {
				overlays: [
					{
						item: 'destruction',
						images: ['kyphosis']
					},
					{
						item: 'osteolytic',
						images: ['femur']
					},
					{
						item: 'bone-pain',
						images: []
					},
					{
						item: 'bone-crisis',
						images: ['bone-crisis']
					},
					{
						item: 'deformity',
						images: ['flask-bone']
					},
					{
						item: 'avn',
						images: ['osteonecrosis']
					},
					{
						item: 'osteopenia',
						images: ['normal-bone', 'osteoporosis']
					}
				]
			}
		},
		computed: {
			getActiveItem () {
				return this.$store.getters.get_itemActive;
			},
			getActiveIndex () {
				return this.overlays.indexOf(this.overlays.find((_) => _.item === this.getActiveItem));
			}
		},
		methods: {
			itemNavClass (index) {
				const prev = index + 1;
				const next = index - 1;

				if (this.overlays[index].item === this.getActiveItem) {
					return 'is-current';
				}
				if (this.overlays[prev] && this.overlays[prev].item === this.getActiveItem) {
					return 'is-prev';
				}
				if (this.overlays[next] && this.overlays[next].item === this.getActiveItem) {
					return 'is-next';
				}
			},
			getItemPrev (index) {
				if (index !== 0) {
					this.overlayItemActive(this.overlays[index - 1].item);
				}
			},
			getItemNext (index) {
				if (index !== this.overlays.length) {
					this.overlayItemActive(this.overlays[index + 1].item);
				}
			},
			overlayItemActive (item) {
                this.$store.dispatch('axn_updateActiveItem', item);
            }
		}
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-320_10_00 {
		height: 100%;
		position: absolute;
		top: 70px;
		left: 0;

		&__content {
			display: none;
			padding: 40px 35px;
			flex-direction: row;
			flex-wrap: wrap;

			&.is-active {
				display: flex;
			}
		}

		.overlay {
			&__title,
			&__subtitle {
				width: 100%;
				pointer-events: none;
			}
			&__title {
				margin-top: -80px;
			}
			&__subtitle {
				font-style: italic;
				font-size: 16px;
				margin-top: -30px;
			}
			&__images,
			&__text {
				margin-top: 15px;
			}
			&__text {
				width: 75%;

				&--bone-pain {
					width: 90%;
				}

				&-copy {
					::v-deep {
						p {
							font-size: 16px;
							color: $grey;
							margin-bottom: 10px;
						}
						h2 {
							font-size: 16px;
							color: $red;
							font-family: 'ITCAvantGardeStd-Demi';
						}
						ul {
							padding-left: 5px;
						}
						li {
							margin-bottom: 0;
						}
					}
				}
			}
			&__images {
				width: 20%;

				&-container {
					position: relative;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin-right: 30px;

					&:last-of-type {
						margin-top: 15px;
					}
				}
				img {
					width: 100%;
					transform: scale(1.1);
				}
				&-caption {
					width: 100%;
					min-height: 26px;
					padding: 5px;
					color: $white;
					background-color: $purple;
					z-index: 1;

					::v-deep p {
						color: $white;
						font-size: 14px;
					}
				}
			}
		}

		.footer {
			position: absolute;
			left: 0;
			bottom: 70px;
			width: 100%;
			height: 114px;
			background-color: $grey-lighter;

			&__nav {
				display: flex;
				flex-direction: row;

				&-item {
					display: none;

					&.is-current {
						display: block;
						position: absolute;
						top: 22px;
						left: 0;
						right: 0;
						color: $grey;
						font-size: 14px;
						text-align: center;
						font-family: 'ITCAvantGardeStd-Demi';
					}
					&.is-prev {
						display: block;
						position: absolute;
						top: 25px;
						right: 580px;
						color: $grey-light;
						font-size: 11px;
						text-align: center;
						font-family: 'ITCAvantGardeStd-Demi';
						::v-deep p {
							color: $grey-light;
						}
					}
					&.is-next {
						display: block;
						position: absolute;
						top: 25px;
						left: 580px;
						color: $grey-light;
						font-size: 11px;
						text-align: center;
						font-family: 'ITCAvantGardeStd-Demi';

						::v-deep p {
							color: $grey-light;
						}
					}
				}
			}
			&__count {
				margin-top: 68px;
				text-align: center;
				font-family: 'ITCAvantGardeStd-Demi';
				font-size: 14px;
			}
			&__arrow {
				display: flex;
				height: 28px;
				width: 19px;
				background: url('../../../../design-system/assets/../shared/core/media/icons/arrow-red.svg');
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 63px;

				&--prev {
					left: 344px;
					transform: rotate(180deg);
				}
				&--next {
					left: 485px;
				}
			}
		}
	}
</style>
