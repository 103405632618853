/**
 * Utilities method
 * Contains axios API calls
 *
 * Author: Sahil David
 */

const axios = require('axios');
const { clientLocalisation } = require('./helpers');

/**
 * Return function to parse axios response
 * @param {Object} response - response from API Call
 */
function returnFunction (response) {
	if (!response) {
		console.error('[LYRA]: Not responding, Please contact vendor');
		return false;
	}

	const { data, status } = response;
	if (status !== 200) {
		console.error(`[LYRA]: ${data.message}`);
	}

	return Object.assign({}, data, { status: response.status });
}

/**
 * API Request method to get data from Lyra API
 * @param {String} url - API url to get data from
 * @param {Object} params - connection params
 * @param {String} method - API request method (Default: GET)
 */
const apiRequest = async (url, params, method = 'get') => {
	/**
	 * Determine if localisation in question needs new Instance
	 */
	let newInstance = false;
	const _localisation  = clientLocalisation();
	if (_localisation && _localisation !== '-') {
		const { localisations } = params;
		const obj = (localisations || []).find((_) => _.localisation === _localisation);

		if (obj && !obj.default && obj.newInstance) {
			newInstance = true;
		}
	}

	const config = {
		method,
		url,
		headers: {
			'Content-Type': 'application/json',
			'org-key': params.orgKey,
			'project-key': params.projectKey,
			'new-instance': newInstance,
		}
	};

	try {
		const response = await axios(config);
		return returnFunction(response);
	} catch (err) {
		const { response } = err;
		return returnFunction(response);
	}
};

/**
 * Generate dynamic URL
 * @param {String} url - url to replace
 * @param {Object} params - params to replace in URL
 * @param {Object} queries - query append at the end of URL
 */
const getDynamicURL = (url, params = {}, queries = {}, defaultOptional = '-') => {
	let _url = url;
	const _parameters = Object.keys(params);
	const _queries = Object.keys(queries);

	/**
	 * Replace dynamic params.
	 */
	_parameters.forEach((parameter) => {
		_url = _url.replace(parameter, params[parameter] || defaultOptional);
	});

	/**
	 * Add queries.
	 */
	_queries.forEach((query, index) => {
		_url += index < 1 ? '?' : '&';
		_url += `${query}=${queries[query]}`;
	});

	return _url;
}

/**
 * Console warning and returns the key
 * @param {String} key - key
 */
const noKeyFound = (key) => {
	console.warn(`[LYRA]: Value not found for key \`${key}\``);
	return key;
}

/**
 * Export
 */
module.exports.apiRequest = apiRequest;
module.exports.getDynamicURL = getDynamicURL;
module.exports.noKeyFound = noKeyFound;
