/**
 * LYRA Data module for Vuex Store
 */

export default {
    state: {
		appManager: [],
		assets: [],
		citations: [],
		debug: false,
	},
	getters: {
		get_appManager (stateObj) {
			return stateObj.appManager;
		},
		get_assets (stateObj) {
			return stateObj.assets;
		},
		get_citations (stateObj) {
			return stateObj.citations;
		},
		get_debug (stateObj) {
			return stateObj.debug;
		},
	},
	mutations: {
		updateAppManager (stateObj, appManager) {
			stateObj.appManager = appManager;
		},
		updateAssets (stateObj, assets) {
			stateObj.assets = assets;
		},
		updateCitations (stateObj, citations) {
			stateObj.citations = citations;
		},
		updateDebugging (stateObj, debug) {
			stateObj.debug = debug;
		},
	},
	actions: {
		axn_updateAppManager (context, appManager) {
			context.commit('updateAppManager', appManager);
		},
		axn_updateAssets (context, assets) {
			context.commit('updateAssets', assets);
		},
		axn_updateCitations (context, citations) {
			context.commit('updateCitations', citations);
		},
		axn_updateDebugging (context, debug) {
			context.commit('updateDebugging', debug);
		},
	}
};
