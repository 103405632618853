import store from "../boot/store";
import { isDevEnvironment } from "../utils";

/**
 * Returns mixin for assets retrieval from LYRA
 */
const LyraMixin = {
	methods: {
		$ly: key => {
			const assets = store.getters.get_assets || [];

			let assetValue = "";
			const asset = assets.find(_ => _.key === key);
			if (!asset) {
				console.warn(`[LYRA]: No assets found for \`${key}\``);
				assetValue = isDevEnvironment() ? key : "";
			} else {
				const htmlElement = asset.value.value.substring(
					0,
					asset.value.value.indexOf(">")
				);
				const dataKey = `${htmlElement} data-content-key="${key}"`;
				assetValue = asset.value.value.replace(htmlElement, dataKey);
			}

			// Debugging
			const lyraDebug = store.getters.get_debug;
			const isDebugging = lyraDebug === "lyra";
			if (isDebugging) {
				return `<div class="lyra-debug"><div class="lyra-debug__key">${key}</div><div class="lyra-debug__value">${assetValue}</div></div>`;
			}

			return assetValue;
		},
		$lye: key => {
			const assets = store.getters.get_assets || [];

			const asset = assets.find(_ => _.key === key);
			return Object.keys(asset || {}).length > 0;
		},
		$lc: key => {
			const citations = store.getters.get_citations || [];

			const citation = citations.find(_ => _.key === key);
			if (!citation) {
				console.warn(`[LYRA]: No citation found for \`${key}\``);
				return isDevEnvironment() ? key : "";
			}

			return citation.value.value;
		}
	}
};

export default LyraMixin;
