<template>
	<div
		class="gaucher-content-svg"
		:class="`gaucher-content-svg__${endpointId}`"
	>
		<div v-if="svgContent" v-html="svgContent" />
		<div v-else class="gaucher-content-svg__placeholder placeholder">No SVG Found</div>
	</div>
</template>

<script>
	export default {
		props: {
			datum: {
				type: Object,
				required: true,
			},
			overlay: {
				type: String,
				default: '',
			}
		},
		data () {
			return {
				ready: false,
				svgContent: null,
			}
		},
		computed: {
			endpointId () {
				return this.overlay ? this.overlay : this.$route.params.endpointId;
			},
		},
		async beforeMount () {
			const { endpointId, datum } = this;
			try {
				this.svgContent = require(`!html-loader!../../../../design-system/assets/../shared/core/media/../shared/core/img/bespoke-content-svg/${endpointId}-${datum.area}.svg`);
			} catch (err) {
				console.warn(err);
			}
		},
	};
</script>

<style lang="scss" scoped>
	.gaucher-content-svg {
		/deep/ svg {
			display: block;
			margin: 0 auto;
		}

		&__placeholder {
			min-height: 300px;
		}
	}
</style>
