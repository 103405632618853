<template>
    <div class="accordion">
        <div
            v-for="(item, index) in items"
            :key="index"
            :class="{'is-open': isOpen(index)}"
            class="accordion__container"
            @click="toggleItem(index)"
        >
            <div 
                v-html="item.title"
                class="accordion__title"
            />
            <div class="accordion__content">
                <div 
                    v-html="item.contentLeft"
                    class="accordion__content-copy accordion__content-copy--left"
                />
                <div 
                    v-html="item.contentRight"
                    class="accordion__content-copy accordion__content-copy--right"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Accordion',
    props: {
        items: {
            type: Array,
            required: true
        },
        itemOpen: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            open: this.itemOpen
        }
    },
    methods: {
        isOpen(index) {
            return index === this.open;
        },
        toggleItem(index) {
            this.open = this.open === index ? -1 : index;
        }
    },
};
</script>

<style lang="scss" scoped>
    .accordion {
        .is-open {
            .accordion__content {
                height: 100px;
                overflow: auto;
                opacity: 1;
                transition: all 0.5s ease;
            }
            .accordion__content-copy {
                opacity: 1;
                transition: all 0.2s ease;
            }
            .accordion__title {
                &:after {
                    transform: rotate(270deg);
                    transition: all 0.3s ease;
                    top: 2px;
                }
            }
        }
        &__container {
            margin-bottom: em(10);
        }
        &__title {
            position: relative;
            width: 100%;
            background-color: $white;
            @include FontDemi();
            font-size: 16px;
            padding: em(10);
            box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);

            &:after {
                content: '';
                height: 31px;
                width: 22px;
                display: block;
                position: absolute;
                right: 10px;
                top: 7px;
                background: url('../../../design-system/assets/../shared/core/media/icons/arrow-red.svg');
                background-repeat: no-repeat;
                transition: all 0.3s ease;
                transform: rotate(90deg);
            }
        }
        &__content {
            height: 0;
            opacity: 0;
            transition: all 0.5s ease;

            background-color: $white;

            display: flex;
            flex-direction: row;
            align-items: flex-start;

            &-copy {
                width: 50%;
                margin: em(20);
                opacity: 0;
                transition: all 0.5s ease;

                ::v-deep h4 {
                    padding-bottom: 10px;
                }

                ::v-deep ul {
                    margin-left: 20px;
                    li {
                        font-size: em(14);
                        line-height: em(20);
                        list-style: disc;
                        padding-left: 0;
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
</style>
