<template>
    <div
        v-if="showGrid"
        class="grid"
    >
        <!-- Grid structure -->
        <div
            class="grid__wrapper"
            v-for="column in columns"
            :key="column"
            :style="{ width: `${columnWidth}px` }"
        >
            <!-- half gutter -->
            <div
                class="grid__gutter"
                :style="{ width: `${gutterWidth / 2}px` }"
            />

            <!-- grid column -->
            <div
                class="grid__column"
                :style="{ width: `${columnWidth}px` }"
            />

            <!-- half gutter -->
            <div
                class="grid__gutter"
                :style="{ width: `${gutterWidth / 2}px` }"
            />
        </div>
    </div>
</template>

<script>
import grid from './config.json';
import { isDevEnvironment } from '@core/utils';

export default {
    data () {
        return {
            columns: [], // columns to draw for grid (mutated)
            columnWidth: 0, // each column width (mutated)
            gutterWidth: 0, // each gutte width (mutated)
            active: false, // state whether grid is visible or not (mutated)
            key: 'g', // key to activate grid,
            version: 'browser'
        };
    },
    computed: {
        showGrid () { /* Returns if the grid is applicable or not */
            return isDevEnvironment() && this.active;
        }
    },
    mounted () {
        this.toggleGrid();

        this.draw();
        window.addEventListener('resize', this.draw);
    },
    methods: {
        draw () {
            const config = grid[this.version];

            if (!config) {
                this.columns = [];
                return;
            }

            this.columns = [...Array(config.columns).keys()];
            this.columnWidth = config.columnWidth;
            this.gutterWidth = config.gutterWidth;
        },
        toggleGrid () {
            window.addEventListener('keypress', (e) => {
                if (e.key === this.key) {
                    this.active = !this.active;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.grid {
    position: fixed;
    top: 0;
    left: 0;
    @include size(100vw, 100vh);
    display: flex;
    flex: 1;
    pointer-events: none;
    z-index: $z-grid;

    &__wrapper {
        background: rgba(0, 0, 0, 0);
        z-index: $z-grid-wrapper;

        display: flex;
        flex: 1;
    }

    &__column {
        background: rgba(#e20000, 0.1);
    }
}
</style>
