<template>
	<div class="gaucher-ctabutton">
        <p
            class="gaucher-ctabutton--text"
            v-html="text"
        ></p>
        <img src="../../../design-system/assets/../shared/core/media/../shared/core/img/svg/red-plus-icon.svg" />
    </div>
</template>

<script>
	export default {
        name: 'CTAButton',
		props: {
			text: {
				type: String,
				required: true,
			}
		}
	};
</script>

<style lang="scss" scoped>
	.gaucher-ctabutton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
        height: 64px;
        &--text {
            color: #565656;
            font-size: 16px;
            line-height: 20px;
            margin-left: 16px;
        }
	}
</style>