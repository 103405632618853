<template>
	<div class="gaucher-not-found">
		<div class="gaucher-not-found__content">
			<div class="gaucher-not-found__title">404</div>
			<div class="gaucher-not-found__sub-title">
				The page you're looking for cannot be found.
			</div>
			<div class="gaucher-not-found__link">
				<router-link to="/" tag="div">
					Back to Homepage
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			navigateHome () {
				this.$router.push('/');
			}
		}
	};
</script>


<style lang="scss" scoped>
	.gaucher-not-found {
		color: #003B4C;

		display: flex;
		align-items: flex-end;

		&__content {
			padding: 5em 1em;
		}

		&__title {
			font: {
				size: 8em;
				weight: 600;
			}
		}

		&__sub-title {
			font-size: 1.3em;
		}

		&__link {
			margin-top: 3em;
			cursor: pointer;
			color: #303030;
		}
	}
</style>

