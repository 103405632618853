<template>
	<div class="gaucher-bespoke-410_00_00">
		<div class="gaucher-bespoke-110_00_00__content">
			<transition name="fade">
				<div
					v-if="infoBoxActive"
					class="info-box__bg"
				/>
			</transition>
			<div
				
				class="gaucher-bespoke-410_10_00__svg"
				v-html="content"
			/>
			<div 
				class="info-box"
				v-for="(overlay, index) in overlays"
				:key="overlay.title"
				:class="infoBoxClass(index)"
			>
				<div class="info-box-header">
					<span 
						class="close-btn"
						@click="toggleOverlay(index)"
					/>
					<p 
						class="info-box-header__title" 
						v-html="overlay.title"
					/>
					<p 
						class="info-box-header__subtitle"
						v-html="overlay.subtitle"
					/>
				</div>
				<div 
					class="info-box-content"
					v-html="overlay.content"
				/>
			</div>
			<div 
				v-for="(overlay, index) in overlays"
				:key="index"
				class="overlay-btn"
				:class="`overlay-btn--type-${index + 1}`"
				@click="toggleOverlay(index)"
			/>
		</div>
	</div>
</template>

<script>
	export default {
        name: 'Bespoke410_00_00',
        data () {
            return {
				content: '',
				overlays: [
					{
						active: false,
						title: this.$ly('410_00_00.overlay1.title'),
						subtitle: this.$ly('410_00_00.overlay1.subtitle'),
						content: this.$ly('410_00_00.overlay1.content')
					},
					{
						active: false,
						title: this.$ly('410_00_00.overlay2.title'),
						subtitle: this.$ly('410_00_00.overlay2.subtitle'),
						content: this.$ly('410_00_00.overlay2.content')
					},
					{
						active: false,
						title: this.$ly('410_00_00.overlay3.title'),
						subtitle: this.$ly('410_00_00.overlay3.subtitle'),
						content: this.$ly('410_00_00.overlay3.content')
					},
				]
            }
		},
		computed: {
			infoBoxActive () {
				let boxActive = false;

				this.overlays.forEach(item => {
					if (item.active) {
						boxActive = true;
						return boxActive;
					}
				});

				return boxActive;
			}
		},
        beforeMount () {
            this.content = require('!html-loader!../../../../design-system/assets/../shared/core/media/../shared/core/img/bespoke-content-svg/410_00_00-img.svg');
		},
		methods: {
			toggleOverlay (index) {
				this.overlays[index].active = !this.overlays[index].active;
			},
			infoBoxClass (index) {
				const isActive = this.overlays[index].active ? 'is-active' : '';
				return `info-box--type-${index + 1} ${isActive}`;
			}
		}
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-410_00_00 {
		height: 100%;
		display: flex;
		justify-content: center;
		margin-top: 70px;

		.info-box {
			width: 214px;
			height: 230px;
			position: absolute;
			box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
			background: $white;

			opacity: 0;
			transform: scale(0);
			transform: origin;
			transition: all 0.5s ease;

			&__bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($overlay-grey, 0.75);
			}

			&.is-active {
				opacity: 1;
				transform: scale(1);
				transition: all 0.5s ease;
				z-index: 1;
			}

			.info-box-header {
				background: $red;
				padding: 15px;
				&__title {
					color: $white;
					@include FontDemi();
					font-size: 16px;
				}
				&__subtitle {
					color: $white;
					@include FontBook();
					font-style: italic;
					font-size: 14px;
					white-space: nowrap;
					::v-deep sup {
						font-size: 55%;
					}
				}
				.close-btn {
					width: 40px;
					height: 40px;
					background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/svg/white-close-btn.svg');
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
					display: inline-block;
					position: absolute;
					left: 175px;
					top: 0px;
				}
			}
			.info-box-content {
				padding: 15px;
			}
			&--type-1 {
				top: 240px;
				left: 103px;
			}

			&--type-3 {
				top: 240px;
				left: 406px;
			}

			&--type-2 {
				top: 240px;
				left: 675px;
			}
		}

		.overlay-btn {
			display: inline-block;
			width: 210px;
			height: 80px;
			position: absolute;
			&--type-1 {
				top: 220px;
				left: 103px;
			}
			&--type-2 {
				top: 220px;
				left: 670px;
				width: 280px;
			}
			&--type-3 {
				top: 220px;
				left: 350px;
				width: 280px;
			}
		}

	}
</style>
