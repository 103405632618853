/**
 * Main method wrapper file
 *
 * Author: Sahil David
 */
const { $lyraAsset, $lyraAssetExist, $lyraAssetsAll } = require('./modules/assets');
const { $lyraCitation, $lyraCitationExist, $lyraCitationsAll } = require('./modules/citations');
const { $lyraAppManger, $lyraAppMangerFull } = require('./modules/appManager');
const { $lyraEndpoints } = require('./modules/endpoints');

/**
 * Wrapper function for returning available methods
 * @param {Object} params - connection params
 */
module.exports = (params) => {
	return {
		/**
		 * Assets methods
		 */
		async asset (key) {
			return await $lyraAsset(params, key);
		},
		async assetExist (key) {
			return await $lyraAssetExist(params, key);
		},
		async assetsAll () {
			return await $lyraAssetsAll(params);
		},
		/**
		 * Citations methods
		 */
		async citation (key) {
			return await $lyraCitation(params, key);
		},
		async citationExist (key) {
			return await $lyraCitationExist(params, key);
		},
		async citationsAll (type = '-') {
			return await $lyraCitationsAll(params, type);
		},
		/**
		 * App Manager methods
		 */
		async appManager (tierId = null) {
			return await $lyraAppManger(params, tierId);
		},
		async appManagerFull () {
			return await $lyraAppMangerFull(params);
		},
		/**
		 * Endpoints methods
		 */
		async endpoints (input = {}) {
			return await $lyraEndpoints(params, input);
		},
	}
}
