<template>
	<div class="gaucher-footer-citations">

		<!-- Close button -->
		<div
			class="gaucher-footer-citations__close"
			@click="$emit('close')"
		>
			<svg-icon icon="close" />
		</div>

		<!-- Head (tabs) -->
		<div class="gaucher-footer-citations__tabs">
			<!-- TODO: Add class to item for Job Bag maker -->
			<div
				v-for="tab in tabs"
				:key="tab"
				class="gaucher-footer-citations__tab jobbag-citations__tab"
				:class="{ 'is-inactive': tabIsInactive(tab) }"
				:ref="tab"
				v-html="$ly(`footer.citations.${tab}`)"
				@click="activateTab(tab)"
			/>
			<div
				class="gaucher-footer-citations__strip"
				ref="strip"
			/>
		</div>

		<!-- Content (citations) -->
		<div class="gaucher-footer-citations__content">
			<div
				v-for="citation in citationsActive"
				:key="citation.key"
				class="gaucher-footer-citations__citation"
			>
				<div
					class="gaucher-footer-citations__citation__prefix"
					:class="`gaucher-footer-citations__citation__prefix--${tabActive}`"
					v-html="getCitationPrefix(citation.key)"
				/>
				<div
					class="gaucher-footer-citations__citation__content"
					v-html="citation.value.value"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import SvgIcon from '../atoms/SvgIcon.vue';

import { getAbbreviations, getFootnotes, getReferences } from '../../../core/lyra-data/citations';

export default {
	components: {
		SvgIcon,
	},
	props: {
		citations: {
			type: Array,
			required: true,
		}
	},
	data() {
		return {
			tabs: ['references', 'footnotes', 'abbreviations'],
			tabActive: '',
		}
	},
	computed: {
		references () {
			return getReferences(this.citations);
		},
		footnotes () {
			return getFootnotes(this.citations);
		},
		abbreviations () {
			return getAbbreviations(this.citations);
		},
		citationsActive () {
			return this[this.tabActive] || [];
		},
	},
	mounted() {
		this.getFirstTabActive();
	},
	methods: {
		/**
		 * Activates given tab and translates x position
		 * of the tabs strip.
		 */
		activateTab (tab) {
			this.tabActive = tab;
			this.translateStrip(tab);
		},
		/**
		 * Translates x position of strip based on active tab.
		 */
		translateStrip (tab) {
			const { strip } = this.$refs;
			const [currentTab] = (this.$refs[tab] || []);

			if (strip && currentTab) {
				const { offsetWidth, offsetLeft } = currentTab;

				strip.style.left = `${offsetLeft + (offsetWidth / 2)}px`;
				strip.style.width = `${offsetWidth}px`;
			}
		},
		/**
		 * True if given tab has no citations.
		 */
		tabIsInactive (tab) {
			return this[tab].length < 1;
		},
		/**
		 * Assigns first item in `tabs` that has citations
		 * as `tabActive`.
		 */
		getFirstTabActive () {
			this.tabs.forEach((tab) => {
				if (this.tabActive.length < 1 && !(this.tabIsInactive(tab))) {
					this.activateTab(tab);
				}
			});
		},
		/**
		 * Returns prefix for the given citation
		 * based on the active tab.
		 */
		getCitationPrefix (key) {
			const { tabActive } = this;
			const [references, footnotes] = this.tabs;

			if (tabActive === references) {
				const number = this.citationsActive.findIndex((_) => _.key === key) + 1;
				return number;
			}
			if (tabActive === footnotes) {
				return '';
			}
			return `${key}`;
		}
	}
}
</script>

<style lang="scss" scoped>

.gaucher-footer-citations {
	border-radius: em(8) em(8) 0 0;
	padding: em(20) em(22);
	background: $white;
	width: calc(100vw - #{em(64)});

	@include desktop {
		width: calc(#{$appIPadMaxWidth} - #{em(64)});
	}


	&__close {
		@include box(18px);
		display: block;
		margin-left: auto;
	}

	&__tabs {
		display: flex;
		color: $grey;
		position: relative;
		padding-bottom: em(14.5);
		margin-bottom: em(23.5);
		border-bottom: 1px solid $white;
	}

	&__tab {
		margin-right: em(30);
		padding: 0 em(14);

		&.is-inactive {
			display: none;
		}
	}

	&__strip {
		position: absolute;
		height: em(6);
		bottom: em(-3);
		background: $red;
		border-radius: 50px;
		transform: translateX(-50%);
		transition: all .3s ease;
	}

	&__content {
		padding: 0 em(15);
		max-height: 450px;
		overflow: auto;
	}

	&__citation {
		display: flex;
		color: $grey;
		margin-bottom: em(15);

		&__prefix {
			font-size: em(14);

			&--references {
				color: $grey;
				font-family: 'Avant-Md';
				min-width: em(33);
			}
			&--abbreviations {
				margin-right: em(18);
			}
		}

		&__content {
			font-size: em(14);
		}
	}
}

</style>
