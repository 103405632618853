const config = require('./config.json');
const { attribute } = config;

/**
 * Returns all encoded attributes from the given SVG.
 * @param {String} svg - stringified svg to get the attributes from.
 */
function getEncodedAttributes (svg) {
    const output = [];
    const splitted = svg.split(`${attribute}="`);

    splitted.forEach((segment, index) => {
        if (!index) return;
        const [element] = segment.split('"');

        if (!(output.includes(element))) {
            output.push(element);
        }
    });

    return output;
}

/**
 * Decodes SVG with its corresponding SVG elements.
 * @param {String} svg - stringified SVG to be decoded.
 */
export default function decodeSVG (svg) {
    let output = svg;
    const encode = getEncodedAttributes(svg);
    // let decodedData;

    encode.forEach((element) => {
        const openingTag = new RegExp(`<g ${attribute}="${element}"`, 'gi');
        const closingTag = new RegExp(`</g><g ${attribute}-closing="${element}"></g>`, 'gi');

        const firstReplace = output.replace(openingTag, `<${element}`);
        const secondReplace = firstReplace.replace(closingTag, `</${element}>`);

        output = secondReplace;
    });

    return output;
}
