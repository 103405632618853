/**
 * Returns given array of objects sorted by specified key.
 * @param array - array to be sorted.
 * @param key - key to be evaluated to sort the array.
 */
export function sortArrayOfObjects (array, key) {
    return array.sort((a, b) => {
        const keyA = a[key].toUpperCase();
        const keyB = b[key].toUpperCase();

        if (keyA < keyB) {
            return -1;
        }
        if (keyA > keyB) {
            return 1;
        }
        return 0;
    });
}

/**
 * Scale App Functionality
 */
export function scaleApp () {
	const body = document.querySelector('#body');
	const app = document.querySelector('#app');

	const bodyWidth = body.offsetWidth;
	const bodyHeight = body.offsetHeight;

	const ratio = 1024 / 768;

	const maxHeight = Math.min(bodyWidth / ratio, bodyHeight);
	const maxWidth = maxHeight * ratio;
	const widthIncrement = Math.min(bodyWidth, maxWidth) / 1024;

	app.style.transform = `scale(${widthIncrement})`;
}

/**
 * Sandbox Environment
 */
export function isDevSandBox () {
    return !!document.querySelector('body[data-sandbox]');
}

/**
 * Production Environment
 */
export function isProduction () {
    return !!document.querySelector('body[data-production]');
}

/**
 * Content update function
 */
 export function appContentUpdate (obj) {
	const update = (obj) => {
		if (typeof obj !== 'object') { return; }

		(Object.keys(obj) || []).forEach((key) => {
			const value = obj[key];
			const elm = document.querySelector(`[data-content-key="${key}"]`);
			if (!elm) { return; }

			elm.innerHTML = value;
		});
	}

	update(obj);
	document.addEventListener('x-app-state-loaded', () => {
		update(obj)
	});
}

export function appContentUpdateElm (obj) {
	const update = (obj) => {
		const { key, value } = obj;
		if (!key) { return; }

		const elm = document.querySelector(key);
		if (!elm) { return; }

		elm.innerHTML = value;
	}

	update(obj);
	document.addEventListener('x-app-state-loaded', () => {
		update(obj)
	});
}

