<template>
	<div class="gaucher-no-template">
		<p>Flow: <b>{{ flowId }}</b></p>
		<p>Asset: <b>{{ endpointId }}</b></p>
		<strong>No Template found for the slide.<br/>Please check LYRA or Development Team.</strong>
	</div>
</template>

<script>
	export default {
		computed: {
			flowId () {
				return this.$route.params.flowId;
			},
			endpointId () {
				return this.$route.params.endpointId;
			},
		},
	};
</script>


<style lang="scss" scoped>
	.gaucher-no-template {
		width: 100%;
		height: 100%;

		color: $grey;
		font-size: 14(em);

		text-align: center;

		display: flex;
		flex-direction: column;
		justify-content: center;

		strong {
			display: block;
			padding-top: 15px;
		}
	}
</style>

