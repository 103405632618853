export default {
    state: {
		itemActive: ''
	},
	getters: {
		get_itemActive (stateObj) {
			return stateObj.itemActive;
		},
	},
    mutations: {
		updateActiveItem (stateObj, activeItem) {
			stateObj.itemActive = activeItem;
		},
	},
	actions: {
		axn_updateActiveItem (context, activeItem) {
			context.commit('updateActiveItem', activeItem);
		},
	}
};