<template>
	<div class="gaucher-asset-bespoke">
		<component
			v-if="activeComponent()"
			:is="activeComponent()"
		/>

		<!-- no bespoke template -->
		<div v-else class="gaucher-asset-bespoke__no-content">
			No Bespoke Template found
		</div>
	</div>
</template>

<script>
	import Bespoke110_00_00 from '../bespoke/Bespoke110_00_00';
	import Bespoke120_00_00 from '../bespoke/Bespoke120_00_00';
	import Bespoke120_10_00 from '../bespoke/Bespoke120_10_00';
	import Bespoke120_05_00 from '../bespoke/Bespoke120_05_00';
	import Bespoke130_00_00 from '../bespoke/Bespoke130_00_00';
	import Bespoke140_00_00 from '../bespoke/Bespoke140_00_00';
	import Bespoke150_00_00 from '../bespoke/Bespoke150_00_00';
	import Bespoke320_00_00 from '../bespoke/Bespoke320_00_00';
	import Bespoke320_10_00 from '../bespoke/Bespoke320_10_00';
	import Bespoke410_00_00 from '../bespoke/Bespoke410_00_00';
	import Bespoke420_00_00 from '../bespoke/Bespoke420_00_00';
	import Bespoke430_00_00 from '../bespoke/Bespoke430_00_00';
	import Bespoke450_00_00 from '../bespoke/Bespoke450_00_00';
	import Bespoke460_00_00 from '../bespoke/Bespoke460_00_00';
	import Bespoke470_00_00 from '../bespoke/Bespoke470_00_00';
	import Bespoke480_00_00 from '../bespoke/Bespoke480_00_00';
	import Bespoke480_10_00 from '../bespoke/Bespoke480_10_00';
	import { overlayInteraction } from '@core/utils';

	export default {
		props: {
			datum: {
				type: Object,
				required: true,
			},
			overlay: {
				type: String,
				default: '',
			}
		},
		data () {
			return {
				componentMap: {
					'110_00_00': Bespoke110_00_00,
					'120_00_00': Bespoke120_00_00,
					'120_10_00': Bespoke120_10_00,
					'120_05_00': Bespoke120_05_00,
					'130_00_00': Bespoke130_00_00,
					'140_00_00': Bespoke140_00_00,
					'150_00_00': Bespoke150_00_00,
					'320_00_00': Bespoke320_00_00,
					'320_10_00': Bespoke320_10_00,
					'410_00_00': Bespoke410_00_00,
					'420_00_00': Bespoke420_00_00,
					'430_00_00': Bespoke430_00_00,
					'450_00_00': Bespoke450_00_00,
					'460_00_00': Bespoke460_00_00,
					'470_00_00': Bespoke470_00_00,
					'480_00_00': Bespoke480_00_00,
					'480_10_00': Bespoke480_10_00,
				}
			}
		},
		computed: {
			endpointId () {
				return this.overlay ? this.overlay : this.$route.params.endpointId;
			},
		},
		methods: {
			/**
			 * Bespoke Component to render
			 */
			activeComponent () {
				const { endpointId, componentMap } = this;
				if (componentMap[endpointId]) {
					overlayInteraction();
					return componentMap[endpointId];
				}

				return null;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.gaucher-asset-bespoke {
		&__no-content {
			display: flex;
			justify-content: center;
			align-items: center;

			min-height: 300px;
			color: $grey;
		}
	}
</style>


