<template>
	<div class="gaucher-footer-menu-wrapper">
		<div class="gaucher-footer-menu">
			<div
				v-for="(item, index) in getMenuFlowItems"
				:key="index"
				class="gaucher-footer-menu__item"
				:class="{ isActive: item.flows === flowId }"
				@click="navigateTo(item)"
			>
				<div v-html="$ly(`footer.nav.${item.flows}`)" />
			</div>
		</div>
	</div>
</template>

<script>
	import { getAppManagerFlow } from '@core/lyra-data';
	import { goToSlide } from '@core/utils';

	export default {
		computed: {
			/**
			 * Get Menu Items
			 */
			getMenuFlowItems () {
				const menuItems = [];
				const endpoints = getAppManagerFlow();

				endpoints.forEach((endpoint) => {
					const exist = menuItems.find((_) => _.flows === endpoint.flows);
					if (!exist) {
						menuItems.push(endpoint);
					}
				});

				return menuItems;
			},
			flowId () {
				return this.$route.params.flowId;
			},
		},
		methods: {
			/**
			 * Navigate to Slide
			 */
			navigateTo (obj) {
				const path = `/slide/${obj.flows}/${obj.endpointId}`;
				const slideId = `${obj.flows}_${obj.endpointId}`;

				goToSlide({ slideId }, () => {
					this.$router.push(path).catch(() => {
						/**
						 * Catch error here
						 */
					});
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.gaucher-footer-menu {
		background: $white;
		box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);
		min-width: 350px;

		&__item {
			padding: 2em 1em 2em 2em;
			border-bottom: 1px solid $tab-grey;
			color: $grey;
			font-size: em(16);
			@include FontBold();

			&:last-child {
				border-bottom: none;
			}

			/deep/ sup {
				font-size: 11px;
			}
		}
		&__item.isActive {
			color: $white;
			background-color: $red;
		}
	}
</style>
