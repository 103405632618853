/**
 * Swipe state object properties
 */
export default {
    state: {
        disabled: false
    },
    mutations: {
        updateSwipeState (state, value) {
            state.disabled = value;
        }
	},
	actions: {
        axn_updateSwipeState (context, value) {
			context.commit('updateSwipeState', value);
		},
    }
};
