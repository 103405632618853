<template>
	<div
		class="gaucher-asset-content"
		:class="`gaucher-asset-content__${endpointId}`"
		v-html="$ly(assetKey)"
	/>
</template>

<script>
	import { overlayInteraction } from '@core/utils';

	export default {
		props: {
			datum: {
				type: Object,
				required: true,
			},
		},
		computed: {
			endpointId () {
				return this.$route.params.endpointId;
			},
			assetKey () {
				return `${this.endpointId}.${this.datum.area}`;
			}
		},
		mounted () {
			overlayInteraction();
		}
	};
</script>

<style lang="scss" scoped>
	.gaucher-flex {
		.gaucher-asset-content {
			display: flex;
			height: 100%;
			align-items: center;
		}
	}
</style>

