<template>
	<div class="gaucher-bespoke-470_00_00">
		<div class="gaucher-bespoke-470_00_00__content">

			<div
				class="gaucher-bespoke-470_00_00__scrollable-content"
				ref="scroll"
			>
				<div
					class="gaucher-bespoke-470_00_00__svg"
					v-html="content"
				/>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
        name: 'Bespoke470_00_00',
        data () {
            return {
                content: '',
            }
        },
        beforeMount () {
            this.content = require('!html-loader!../../../../design-system/assets/../shared/core/media/../shared/core/img/bespoke-content-svg/470_00_00-img.svg');
        },
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-470_00_00 {
		height: 100%;
		display: flex;
        justify-content: flex-start;
        margin-top: 40px;

        &__scrollable-content {
            overflow-y: scroll;
            max-height: em(420);
            position: relative;
            padding-right: 20px;

        }

        &__content {
            position: relative;
			width: 100%;
        }

        &__svg {
            width: 100%;

            ::v-deep svg {
                width: 100%;
            }
        }
	}
</style>
