<template>
	<div
		class="gaucher-content-image"
		:class="`gaucher-content-image__${endpointId}`"
	>
		<img v-if="imageSource" :src="imageSource" />
		<div v-else class="gaucher-content-image__placeholder placeholder">Image not available</div>

		<div
			v-if="$lye(captionKey)"
			class="gaucher-content-image__caption"
			v-html="$ly(captionKey)"
		/>
	</div>
</template>

<script>
	export default {
		props: {
			datum: {
				type: Object,
				required: true,
			},
			overlay: {
				type: String,
				default: '',
			}
		},
		data () {
			return {
				ready: false,
				imageSource: null,
			}
		},
		computed: {
			endpointId () {
				return this.overlay ? this.overlay : this.$route.params.endpointId;
			},
			captionKey () {
				return `${this.endpointId}.${this.datum.area}.caption`;
			}
		},
		async beforeMount () {
			const { endpointId, datum } = this;
			try {
				this.imageSource = require(`../../../../design-system/assets/../shared/core/media/../shared/core/img/content/${endpointId}-${datum.area}.png`);
			} catch (err) {
				console.warn(err);
			}
		},
	};
</script>

<style lang="scss" scoped>
	.gaucher-content-image {
		img {
			margin: 1em auto;
			display: block;
			max-height: 480px;
		}

		&__placeholder {
			min-height: 300px;

			background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/svg/hexagon.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;

			color: $white;
		}

		&__caption {
			padding: 0.5em 0;
			text-align: center;
			/deep/ &, p {
				font-size: em(12);
			}
		}
	}
</style>
