/**
 * App Manager functions
 *
 * Author: Sahil David
 */

const { apiRequest } = require('../utils');
const { clientLocalisation } = require('../helpers');
const { cachedData } = require('../cached/utils');
const { appManagerAPI, appManagerFullAPI } = require('../../config/endpoints');

/**
  * Returns app manager value based on the key
  * @param {Object} params - connection params
  * @param {String} tierId - tierId to get value for (optional)
  */
async function $lyraAppManger(params, tierId) {
	const cacheResponse = await cachedData('lyraAppManger', { tierId });
	if (cacheResponse.exit) {
		return cacheResponse;
	}

	const url = appManagerAPI({
		':localisation': clientLocalisation(),
		':tierId': tierId,
	}, { domain: params.domain });

	const _response = await apiRequest(url, params);
	return _response;
}

/**
  * Returns full app manager value based on the key
  * @param {Object} params - connection params
  */
 async function $lyraAppMangerFull(params) {
	const cacheResponse = await cachedData('lyraAppMangerFull');
	if (cacheResponse.exit) {
		return cacheResponse;
	}

	const url = appManagerFullAPI({
		':localisation': clientLocalisation(),
	}, { domain: params.domain });
	const _response = await apiRequest(url, params);
	return _response;
}

/**
 * Export Functions
 */
module.exports.$lyraAppManger = $lyraAppManger;
module.exports.$lyraAppMangerFull = $lyraAppMangerFull;
