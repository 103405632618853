<template>
	<div class="gaucher-slide">
		<div
			v-if="qrcodeRequired"
			class="gaucher-slide--qrcode"
		>
			<!-- QR Code on basis of localisation -->
			<img
				v-if="activeLocalisationId === 'en-ie'"
				src="../../design-system/assets/../shared/core/media/../shared/core/img/qr-code-en-ie.png"
			/>
			<img
				v-else
				src="../../design-system/assets/../shared/core/media/../shared/core/img/qr-code.png"
			/>
		</div>
		<component
			:is="activeTemplate()"
			:key="timestamp"
		/>

		<div
			v-if="lyraDebug"
			class="gaucher-template-tag"
		>
			Template: {{ overlayTemplate ? overlayTemplate : template }}
		</div>
	</div>
</template>

<script>
	import CoreTemplateLibrary from '@ui/components/templates/CoreTemplateLibrary';
	import TitleCardTemplate from '@ui/components/templates/TitleCardTemplate';
	import NoTemplateFound from '@ui/components/templates/NoTemplateFound';

	import { getAppManagerEndpoint, getAppManagerTier } from '@core/lyra-data';
	import { activeLocalisation } from '@core/localisation';

	export default {
		data () {
			return {
				templateMap: {
					'title-card': TitleCardTemplate
				},
				templateLibrary: CoreTemplateLibrary,
				noTemplateFound: NoTemplateFound,
				timestamp: Date.now(),
				template: '',
			}
		},
		computed: {
			flowId () {
				return this.$route.params.flowId;
			},
			endpointId () {
				return this.$route.params.endpointId;
			},
			lyraDebug () {
				return this.$store.getters.get_debug;
			},
			overlayTemplate () {
				return this.$store.getters.get_overlayTemplate;
			},
			qrcodeRequired () {
				const endpointData = getAppManagerEndpoint(this.endpointId);
				return !Object.prototype.hasOwnProperty.call(endpointData, 'noqrcode');
			},
			activeLocalisationId () {
				return activeLocalisation();
			}
		},
		watch: {
			$route () {
				this.timestamp = Date.now();
			}
		},
		methods: {
			/**
			 * Return active template component
			 */
			activeTemplate () {
				const { endpointId, templateMap, templateLibrary, noTemplateFound } = this;
				const endpointObj = getAppManagerEndpoint(endpointId);
				const lyraTemplates = getAppManagerTier('template');

				const { template } = endpointObj;
				this.template = template;

				const keys = Object.keys(templateMap);

				if (keys.includes(template)) {
					return templateMap[template]
				}

				const existTemplate = lyraTemplates.find((_) => _.endpointId === template);
				if (existTemplate) {
					return templateLibrary;
				}

				return noTemplateFound;
			}
		}
	};
</script>


<style lang="scss" scoped>
	.gaucher-slide {
		width: 100%;
		&--qrcode {
			position: absolute;
			left: 867px;
			top: 120px;
			img {
				@include box(114px);
			}
		}

		&:before {
			content: "";
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			margin: 0 auto;
			width: 100%;
			height: 107px;

			@include containerSize();
			@include desktop {
				@include centerX();
			}

			background: url('../../design-system/assets/../shared/core/media/../shared/core/img/svg/home-logo.svg');
			background-repeat: no-repeat;
			background-position: top right;

			z-index: $z-home-content-before;
	}
}
</style>

