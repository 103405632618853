/**
 * Overlays module for Vuex Store
 */

export default {
    state: {
		overlay: '',
		template: '',
	},
	getters: {
		get_overlay (stateObj) {
			return stateObj.overlay;
		},
		get_overlayTemplate (stateObj) {
			return stateObj.template;
		},
	},
	mutations: {
		updateOverlay (stateObj, overlay) {
			stateObj.overlay = overlay;
		},
		updateOverlayTemplate (stateObj, template) {
			stateObj.template = template;
		},
	},
	actions: {
		axn_updateOverlay (context, overlay) {
			context.commit('updateOverlay', overlay);
		},
		axn_updateOverlayTemplate (context, template) {
			context.commit('updateOverlayTemplate', template);
		},
		axn_closeOverlay (context) {
			context.commit('updateOverlay', '');
			context.commit('updateOverlayTemplate', '');
		},
	}
};
