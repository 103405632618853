/**
 * Main package entry file
 * Vue installations
 *
 * Author: Sahil David
 */
const methods = require('./core/methods');

/**
 * Initial params object
 */
let params = {
    orgKey: '',
    projectKey: '',
    loud: false,
    domain: '',
    localisations: [],
};

/**
 * Vue install support
 */
module.exports.vueLyra = {
    install: async (Vue, options = {}) => {
        if (!Vue) { return; }
        params = options;

        Vue.prototype.$lyra = methods(params);
        window.lyraClient = window.lyraClient || {};
    },
};
