<template>
	<div
		class="gaucher-content-content"
		:class="`gaucher-content-content__${endpointId}`"
		v-html="content"
		:data-content-key="`${endpointId}-content`"
	/>
</template>

<script>
	import { overlayInteraction } from '@core/utils';
	import decodeSVG from '@core/decode';

	export default {
		props: {
			datum: {
				type: Object,
				required: true,
			},
			overlay: {
				type: String,
				default: '',
			}
		},
		data () {
			return {
				ready: false,
				content: '',
			}
		},
		computed: {
			endpointId () {
				return this.overlay ? this.overlay : this.$route.params.endpointId;
			},
		},
		async beforeMount () {
			await this.queryData();
		},
		methods: {
			/**
			 * Query data for endpoint
			 */
			async queryData () {
				const { endpointId, overlay } = this;
				const tierId = overlay ? 'overlays' : 'endpoints';

				const response = await this.$lyra.endpoints({
					tierId,
					endpointId: endpointId,
				});
				if (!response.success) { return; }

				const { data } = response;
				const [record] = data;

				this.content = decodeSVG(record.content);
				this.ready = true;

				setTimeout(() => {
					overlayInteraction();
				}, 100);
			}
		},
	};
</script>

<style lang="scss" scoped>
	.gaucher-content-content {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.gaucher-content-text & {
			align-items: flex-start;
		}
	}
</style>
