<template>
	<div class="gaucher-bespoke-460_00_00">
		<div class="gaucher-bespoke-460_00_00__content">
            <div
                class="compare-btn"
                @click="openPI('paeds-algorithm-gaucher')"
            >
                Paediatric algorithm
            </div>
            <div
                class="gaucher-bespoke-460_00_00__svg"
                v-html="content"
            />
		</div>
	</div>
</template>

<script>
    import { openPDF } from '@core/utils';

	export default {
        name: 'Bespoke460_00_00',
        data () {
            return {
                content: '',
            }
        },
        beforeMount () {
            this.content = require('!html-loader!../../../../design-system/assets/../shared/core/media/../shared/core/img/bespoke-content-svg/460_00_00-img.svg');
        },
        methods: {
            openPI (pdf) {
				openPDF(pdf);
			}
        }
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-460_00_00 {
		height: 100%;
		display: flex;
        justify-content: flex-start;
        margin-top: 40px;


		&__content {
            .compare-btn {
                background-color: $white;
                padding: 15px 30px;
                border-radius: 22px;
                box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
                text-transform: uppercase;
                font-size: em(13);
                line-height: em(16);
                @include FontDemi();
                display: inline-block;
                position:absolute;
                top: 130px;
                right: 180px;
            }
        }
	}
</style>
