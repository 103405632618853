<template>
	<div class="gaucher-bespoke-120_00_00">
        <h2 
            class="patient__title"
            v-html="$ly(`120_00_00.patient.title`)"
        />
        <h3 
            class="patient__subtitle"
            v-html="$ly(`120_00_00.title.subtitle`)"
        />

        <div class="content-header">
            <div class="content-header--tabs">
                <div
                    v-for="tab in tabs"
                    :key="tab"
                    class="tab"
                    :class="{ 'is-active': tabIsActive(tab) }"
                    :ref="tab"
                    v-html="$ly(`120_00_00.${tab}.label`)"
                    @click="activateTab(tab)"
                />

            </div>
        </div>

        <div class="content-copy">
            <div
                class="tab-content"
                v-if="tabActive === 'tab1'"
                v-html="$ly('120_00_00.tab1.copy')"
            />
            <div
                class="tab-content"
                v-if="tabActive === 'tab2'"
                v-html="$ly('120_00_00.tab2.copy')"
            />
            <div
                class="tab-content"
                v-if="tabActive === 'tab3'"
            >
                <div
                    class="tab-content--copy"
                    v-html="$ly('120_00_00.tab3.copy')"
                />
                <div class="tab-content--images">
                    <div
                        class="cell-image-one"
                        @click="activateZoomImage('120_05_00')"
                    >
                    </div>
                    <div
                        class="cell-image-two"
                        @click="activateZoomImage('120_10_00')"
                    >
                    </div>
                </div>
            </div>

        </div>
	</div>
</template>
<script>

	export default {
        name: 'Bespoke120_00_00',
        data() {
            return {
                tabs: ['tab1', 'tab2', 'tab3', 'tab4'],
                tabActive: 'tab1',
            }
        },
		methods: {
            activateTab (tab) {
                if (tab === 'tab4') {
                    this.$store.dispatch('axn_updateOverlay', '350_10_00');
                } else {
                    this.tabActive = tab;
                }
            },
            tabIsActive (tab) {
                return tab === this.tabActive;
            },
            activateZoomImage (overlay) {
                this.$store.dispatch('axn_updateOverlay', overlay);
            }
		}
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-120_00_00 {
        height: 100%;
        width: 530px;
        margin-top: 60px;
        margin-left: auto;
        .patient {
            &__title {
                font-size: 30px;
                line-height: 36px;
                @include FontMedium();
                width: 75%;
                margin-top: -77px;
            }
            &__subtitle {
                font-size: 16px;
                line-height: 30px;
                margin-bottom: 10px;
            }
        }
		.content-header {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 22px;
           &--tabs {
               display: flex;
               border-bottom: 1px solid $red;
               .tab {
                    background: $white;
                    padding: 12px 12px 2px 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    text-align: center;
                    border-bottom: solid 10px $white;

                   ::v-deep p {
                    font-size: 14px;
                    line-height: 18px;
                    color: $default-grey;
                    @include FontDemi();
                   }
               }
               .tab.is-active {
                   box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
                   border-bottom: solid 10px $red;
               }
               .tab:last-child {
                   width: 105px;
                   background-color: $tab-grey;
                   border-bottom: solid 10px $tab-grey;
                   margin-right: 0px;
               }
           }
        }
        .content-copy {
            display: flex;
            justify-content: flex-end;
            .tab-content--images {
                margin-top: 20px;
                display: flex;
            }
            .cell-image-one {
                background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/content/120_00_00-cellimage1.png');
                width: 220px;
                height: 140px;
                background-repeat: no-repeat;
                background-size: contain;
            }
            .cell-image-two {
                background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/content/120_00_00-cellimage2.png');
                width: 227px;
                height: 140px;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
	}
</style>
