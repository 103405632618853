/**
 * Helper functions for localisations
 */

const defaultLocalisation = 'en-gl';

/**
 * Verify a localisation string or get localisation
 * @param {String} verifyLocalisation - localisation to verify
 */
export function activeLocalisation (verifyLocalisation = null) {
    window.lyraClient = window.lyraClient || {};

    const body = document.querySelector('body');
    const bodyAttribute = body.getAttribute('data-localisation');

    let _localisation = defaultLocalisation;
    if (bodyAttribute) {
        _localisation = bodyAttribute;
    } else if (window.lyraClient.localisation) {
        _localisation = window.lyraClient.localisation;
    }

    if (verifyLocalisation) {
        return verifyLocalisation === _localisation;
    }
    return _localisation;
}

/**
 * Apply localisation guard check
 */
export function localisationGuard (route) {
    const { query } = route;
    const body = document.querySelector('body');
    const bodyAttribute = body.getAttribute('data-localisation');

    /**
     * Exposing windows object
     */
    window.lyraClient = window.lyraClient || {};
    window.activeLocalisation = activeLocalisation;

    /**
     * Check URL Query, Setting appropriate value
     */
    let _localisation = defaultLocalisation;
    if (query.localisation) {
        _localisation = query.localisation;
    } else if (bodyAttribute) {
        _localisation = bodyAttribute;
    } else if (window.lyraClient.localisation) {
        _localisation = window.lyraClient.localisation;
    } else {
        const storedLocalisation = sessionStorage.getItem('lyra_localisation');
        if (storedLocalisation) {
            _localisation = storedLocalisation;
            sessionStorage.removeItem('lyra_localisation');
        }
    }

    /**
     * Apply localisation variables
     */
    if (_localisation) {
        window.lyraClient.localisation = _localisation;
        body.setAttribute('data-localisation', _localisation);
    }
}

/**
 * Save current localisation in a memory
 */
export function saveLocalisation () {
    const localisation = activeLocalisation();
    sessionStorage.setItem('lyra_localisation', localisation);
}
