/* eslint-disable */
/**
 * Content methods
 *
 * Author: Sahil David
 */

module.exports = {
	lyraAsset (db, params) {
		const { assets } = db;
		if (!assets) { return { error: true }; }

		const { key } = params;
		const record = assets.find((_) => _.key === key);
		if (!record) { return { error: true }; }

		return {
			success: true,
			data: record.value,
		}
	},

	lyraAssetsAll (db, params) {
		const { assets } = db;
		if (!assets) { return { error: true, data: [] }; }

		return {
			success: true,
			data: assets,
		}
	},

	lyraCitation (db, params) {
		const { citations } = db;
		if (!citations) { return { error: true }; }

		const { key } = params;
		const record = citations.find((_) => _.key === key);
		if (!record) { return { error: true }; }

		return {
			success: true,
			data: record.value,
		}
	},

	lyraCitationsAll (db, params) {
		const { citations } = db;
		if (!citations) { return { error: true, data: [] }; }

		return {
			success: true,
			data: citations,
		}
	},

	lyraAppManger (db, params) {
		const { appManager } = db;
		if (!appManager) { return { error: true, data: [] }; }

		let data = appManager;
		if (params.tierId) {
			data = appManager.find((_) => _.tierId === params.tierId) || [];
		}

		return {
			success: true,
			data,
		}
	},

	lyraAppMangerFull (db, params) {
		const { appManager } = db;
		if (!appManager) { return { error: true, data: [] }; }

		return {
			success: true,
			data: appManager,
		}
	},

	lyraEndpoints (db, params) {
		const { appManager } = db;
		if (!appManager) { return { error: true, data: [] }; }

		const { tierId, endpointId } = params;
		const tierData = appManager.find((_) => _.tierId === tierId) || {};

		let endpoints = tierData.endpoints || [];
		endpoints.forEach((obj) => {
			obj.tierId = tierId;
		});

		if (endpointId) {
			endpoints = endpoints.filter((_) => _.endpointId === endpointId) || [];
		}

		return {
			success: true,
			data: endpoints,
		}
	},
}
