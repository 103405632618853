<template>
	<div class="gaucher-bespoke-480_10_00">
		<div class="gaucher-bespoke-480_10_00__content">
			<div class="info-box">
				<div class="info-box--title">ERT vs SRT treatment options</div>
				<div class="info-box--content">
                    <div class="info-box-copy">
                        <p>ERT<sup>1</sup></p>
                        <ul>
                            <li><span class="green-plus">+</span> Fewer AEs</li>
                            <li><span class="green-plus">+</span> More drug history</li>
                            <li><span class="green-plus">+</span> Widely available</li>
                            <li><span class="red-minus">-</span> Inconvenient</li>
                            <li><span class="red-minus">-</span> Invasive</li>
                            <li><span class="red-minus">-</span> Inconsistent drug  exposure</li>
                        </ul>
                    </div>
                    <div class="info-box-copy">
                        <p>SRT<sup>1</sup></p>
                        <ul>
                            <li><span class="green-plus">+</span> Convenience</li>
                            <li><span class="green-plus">+</span> Non-invasive</li>
                            <li><span class="green-plus">+</span> Consistent drug exposure</li>
                            <li><span class="red-minus">-</span> Side effects</li>
                            <li><span class="red-minus">-</span> Drug interactions</li>
                            <li><span class="red-minus">-</span> Remembering regime</li>
                        </ul>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Bespoke480_10_00',
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-480_10_00 {
		height: 100%;
		display: flex;
		justify-content: center;
		&__content {
            display: flex;
            ::v-deep .info-box {
                width: 560px;
                background: $white;
                &--title {
                    background: $red;
                    color: $white;
                    text-align: center;
                    font-size: 17px;
                    line-height: 26px;
                    padding: 15px 0;
                    @include FontDemi();
                    margin-bottom: 15px;
                }
                &--content {
                    display: flex;
                    padding: 25px;
                    justify-content: space-between;
                    .info-box-copy {
                        p {
                            font-size: 18px;
                            line-height: 24px;
                            @include FontMedium();
                        }
                        ul li {
                            margin-left: em(-16);
                        }
                        ul li::before {
                            content: none;
                        }
                        .green-plus {
                            color: #B2BB1C;
                        }
                        .red-minus {
                            color: #E20714;
                        }
                    }
                }
                &--content p {
                    font-size: em(16);
                    line-height: em(22);
                }

            }
		}
	}

</style>
