/**
 * Exporting endpoints for API Interaction
 *
 * Author: Sahil David
 */
const { getDynamicURL } = require('../core/utils');

const baseUrl = 'https://lyra.huumun.dev/api/v3/gateway';
const baseUrlFn = (domain) => {
	if (domain) {
		return `${domain}/api/v3/gateway`;
	}
	return baseUrl;
}
module.exports.baseUrl = baseUrl;
module.exports.baseUrlFn = baseUrlFn;

/**
 * Connection API
 */
module.exports.connectionAPI = (options = {}) => {
	const { domain } = options;
	return `${baseUrlFn(domain)}/connect`;
}

/**
 * Assets API
 */
module.exports.assetAPI = (params, options = {}) => {
	const { domain } = options;
	const url = `${baseUrlFn(domain)}/:localisation/assets/:assetKey`;
	return getDynamicURL(url, params);
}

module.exports.assetsAllAPI = (params, options = {}) => {
	const { domain } = options;
	const url = `${baseUrlFn(domain)}/:localisation/assets`;
	return getDynamicURL(url, params);
}

/**
 * Citations API
 */
module.exports.citationAPI = (params, options = {}) => {
	const { domain } = options;
	const url = `${baseUrlFn(domain)}/:localisation/citations/:citationKey`;
	return getDynamicURL(url, params);
}

module.exports.citationsAllAPI = (params, options = {}) => {
	const { domain } = options;
	const url = `${baseUrlFn(domain)}/:localisation/citations-all/:citationType`;
	return getDynamicURL(url, params);
}

/**
 * App Manager API
 */
module.exports.appManagerAPI = (params, options = {}) => {
	const { domain } = options;
	const url = `${baseUrlFn(domain)}/:localisation/app-manager/:tierId`;
	return getDynamicURL(url, params, {}, '');
}

module.exports.appManagerFullAPI = (params, options = {}) => {
	const { domain } = options;
	const url = `${baseUrlFn(domain)}/:localisation/app-manager-full`;
	return getDynamicURL(url, params, {}, '');
}

/**
 * Endpoint API
 */
module.exports.endpointAPI = (params, options = {}) => {
	const { domain } = options;
	const url = `${baseUrlFn(domain)}/:localisation/app-manager/:tierId/endpoints/:endpointId`;
	return getDynamicURL(url, params, {}, '');
}

