import Vue from 'vue';
import Vuex from 'vuex';

import app from '../store/app';
import lyra from '../store/lyra';
import swipe from '../store/swipe';
import footer from '../store/footer';
import overlay from '../store/overlay';
import carousel from '../store/carousel';

/**
 * Initialising Vuex store
 */
Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
		app,
		lyra,
		swipe,
		footer,
		overlay,
		carousel
    }
});