/* eslint-disable no-undef */
import veevaConfig from '../../../veeva.config';
import { activeLocalisation } from '../localisation';

/**
 * Check if is Veeva or not.
 * @return true if it's Veeva, false if it's not.
 */
export function isVeeva () {
    return !!document.querySelector('body[data-veeva]');
}

/**
 * Veeva Slide Id
 * @param {*} config
 */
export function veevaSlideId (slideId) {
    const { prefix, localisation, suffix } = veevaConfig;
	const localisationTag = activeLocalisation() || localisation;

    const veevaIdArr = [prefix, localisationTag, suffix, slideId];
    return veevaIdArr.join('_');
}

/**
 * Navigates to given slide id
 * @param {String} slideId
 */
export function goToSlide (veevaParams, browserFunction, skipVeevaConversion = false) {
    if (isVeeva()) {
        const { slideId, presentationId } = veevaParams;
        const veevaSlideIdName = skipVeevaConversion ? slideId : `${veevaSlideId(slideId)}.zip`;

        com.veeva.clm.gotoSlide(veevaSlideIdName, (presentationId || ''));
    } else {
        browserFunction();
    }
}

/**
 * Navigates to PDF in Veeva or in Browser
 * @param {String} pdfId - PDF name
 */
export function openPDF (pdfId) {
    const { localisation } = veevaConfig;
	const localisationTag = activeLocalisation() || localisation;
    const pdfPath = `pdf/${localisationTag}/${pdfId}.pdf`;

    if (isVeeva()) {
        const veevaPath = `../shared/${pdfPath}`;
        window.location.href = veevaPath;
    } else {
		const browserPath = `./${pdfPath}`;
        window.location.href = browserPath;
    }
}
