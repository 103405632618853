/**
 * Endpoints functions
 *
 * Author: Sahil David
 */

const { apiRequest } = require('../utils');
const { clientLocalisation } = require('../helpers');
const { cachedData } = require('../cached/utils');
const { endpointAPI } = require('../../config/endpoints');

 /**
  * Returns endpoints value based on the key
  * @param {Object} params - connection params
  * @param {String} endpointId - endpointId to get value for (optional)
  */
async function $lyraEndpoints(params, input) {
	if (!input.tierId) { return []; }

	const { tierId, endpointId } = input;
	const cacheResponse = await cachedData('lyraEndpoints', { tierId, endpointId });
	if (cacheResponse.exit) {
		return cacheResponse;
	}

	const url = endpointAPI({
		':localisation': clientLocalisation(),
		':tierId': tierId,
		':endpointId': endpointId,
	}, { domain: params.domain });
	const _response = await apiRequest(url, params);
	return _response;
}

/**
 * Export Functions
 */
module.exports.$lyraEndpoints = $lyraEndpoints;
