/* eslint-disable */

/**
 * Cached method
 * Contains methods for local database
 *
 * Author: Sahil David
 */

 const contentMethods = require('./contentMethods');
 const { clientDeployRoute, clientMode, clientDatabase } = require('../helpers');

 const fileLyraConfig = require('../../lyra.config.json');
 const fileDatabase = require('../../caching/database.json');

 /**
   * Fetch local JSON data
   */
 async function fetchData (path) {
   const response = await fetch(path, {
     credentials: 'include',
     mode: 'no-cors',
   });

   const json = await response.json().catch((error) => {
     console.error(error);
     return { error: true };
   });

   return json;
 }

 /**
  * Get app mode data (live | production)
  */
 async function getMode () {
   // Local file
   if (fileLyraConfig && fileLyraConfig.mode) {
     return fileLyraConfig;
   }

   return { mode: 'production' };
 }

 /**
  * Get database from local
  */
 async function getDatabase () {
   const mode = await getMode();
   if (mode && mode.mode === 'production') {
     return fileDatabase;
   }

   return {};
 }

 /**
  * Cache data wrapper functions
  */
 async function cachedData (domain, params) {
   // get mode
   const modeResponse = await getMode();
   if (modeResponse.error) { return modeResponse; }
   if (modeResponse.mode === 'live') {
     return { skip: true };
   }

   // get local database
   const dbResponse = await getDatabase();
   if (dbResponse.error) { return dbResponse; }

   const returnObj = { exit: true };

   // local content methods
   if (contentMethods[domain]) {
     const _response = contentMethods[domain](dbResponse, params);
     return Object.assign({}, returnObj, _response);
   }

   return returnObj;
 }

 /**
  * Export Functions
  */
 module.exports.cachedData = cachedData;
