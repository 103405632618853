<template>
	<div class="gaucher-bespoke-120_05_00">
		<div 
			class="gaucher-bespoke-120_05_00__content"
		>
			<img :src="require('../../../../design-system/assets/../shared/core/media/../shared/core/img/content/120_05_00-image.png')" />
			<div 
                v-html="$ly('120_05_00.image.caption-zoom')"
                class="image-caption"
            />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Bespoke120_05_00',
		data () {
			return {
			}
		},
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-120_05_00 {
        width: 50%;
        margin: auto;
        margin-top: 50px;
        background: white;
        padding: 10px;

        .image-caption {
			margin-top: 10px;
			font-family: 'ITCAvantGardeStd-Demi';

			::v-deep {
				font-size: 14px;
			}
        }
	}
</style>
