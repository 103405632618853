<template>
	<div class="gaucher-title-card">
		<!-- Content Box -->
		<div class="gaucher-title-card__content">
			<div
				class="gaucher-title-card__content--title"
				:class="`title-${endpointId}`"
				v-html="SlideTitle"
			>
			</div>
			<div
				class="gaucher-title-card__content--cta"
				:class="`grid-count-${titlecards.length}`"
			>
				<CTA-card
					v-for="(card, index) in titlecards"
					:key="index"
					:text="card.cardlabel"
					@click.native="navigateTo({ endpointId:card.endpointId, flows: card.flow })"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import CTACard from '@ui/components/atoms/CTACard';
	import { goToSlide } from '@core/utils';

	export default {
		components: {
			CTACard,
		},
		data () {
			return {
				titlecards: [],
			}
		},
		computed: {
			flowId () {
				return this.$route.params.flowId;
			},
			endpointId () {
				return this.$route.params.endpointId;
			},
			SlideTitle () {
				return this.flowId === 'home' ? '' : this.$ly(`${this.endpointId}.title`);
			}
		},
		async mounted () {
			await this.getEndpointCards();
		},
		methods: {
			async getEndpointCards () {
				const endpoint = await this.$lyra.endpoints({
					tierId: 'titlecards',
					endpointId: this.endpointId,
				});
				if (endpoint.success) {
					const { data } = endpoint;
					const [record] = data || {};
					const { datasets } = record || [];
					const _record = (datasets || []).find((_) => _.chartId === 'data');
					this.titlecards = _record.data;
				}
			},
			/**
			 * Navigate to Slide
			 */
			navigateTo (obj) {
				const path = `/slide/${obj.flows}/${obj.endpointId}`;
				const slideId = `${obj.flows}_${obj.endpointId}`;

				goToSlide({ slideId }, () => {
					this.$router.push(path).catch(() => {
						/**
						 * Catch error here
						 */
					});
				});
			}
		}
	};
</script>


<style lang="scss" scoped>
	.gaucher-title-card {
		height: 100%;
		display: flex;
		justify-content: center;
		&__content {
			&--title {
				color: white;
				font-size: 48px;
				line-height: 36px;
				padding-top: 90px;
				text-align: center;
				@include FontDemi();
			}
			&--title.title-010_00_00 {
				padding-top: 129px;
			}
			&--cta {
				display: grid;
				grid-template-rows: 133px 133px;
				column-gap: 20px;
				row-gap: 24px;
				padding-top: 111px;
			}
			&--cta.grid-count-1 {
				width: 645px;
			}
			&--cta.grid-count-2 {
				width: 645px;
			}
			&--cta.grid-count-4 {
				width: 836px;
				grid-template-columns: 408px 408px;
			}
		}
	}
</style>

