
import Vue from 'vue';
import Router from 'vue-router';

/**
 * Router components
 */
import Slide from '@ui/views/Slide.vue';
import NotFound from '@ui/views/NotFound.vue';

/**
 * Configure Router object
 */
Vue.use(Router);
export default new Router({
	routes: [
		{
			path: '/',
			redirect: '/slide/home/010_00_00'
		},
		{
			path: '/slide/:flowId/:endpointId',
			name: 'slide',
			component: Slide,
			props (route) {
				return route.query || {};
			}
		},
		{
			path: '*',
			name: 'notFound',
			component: NotFound,
		},
	],
});