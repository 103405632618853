import store from '../boot/store';

/**
 * Returns app manager flow data
 */
export function getAppManagerFlow () {
	let returnData = [];
	const appManager = (store.getters.get_appManager || []);

	// Flows
	const flows = appManager.find((_) => _.tierId === 'flows');
	if (!flows) { return returnData; }

	// Tier Endpoints
	const tierEndpoints = flattenEndpoints(flows.endpoints || []).sort((a, b) => parseInt(a.order) - parseInt(b.order));

	// Endpoints
	const endpoints = appManager.find((_) => _.tierId === 'endpoints');
	const slides = flattenEndpoints(endpoints.endpoints || []);

	tierEndpoints.forEach((_) => {
		const _flowEndpoints = slides.filter((obj) => obj.flows === _.endpointId);
		const _tempFlow = _flowEndpoints.sort((a, b) => parseInt(a.order) - parseInt(b.order));

		_tempFlow.forEach((_obj, index) => {
			if (!_obj.copyendpoint) { return; }
			console.log('copy found');
			const { copyendpoint } = _obj;
			const replaceEndpoint = Object.assign({}, slides.find((slide) => slide.endpointId === copyendpoint));
			const temp = _tempFlow[index];
			_tempFlow[index] = replaceEndpoint;
			_tempFlow[index].flows = temp.flows;
			_tempFlow[index].order = temp.order;
		});

		returnData = returnData.concat(_tempFlow);
	});

	return returnData;
}

/**
 * Get the endpoint records
 * @param {String} endpointId - endpointId to get the record for
 */
export function getAppManagerEndpoint (endpointId) {
	const appManager = (store.getters.get_appManager || []);

	// Endpoints
	const endpoints = appManager.find((_) => _.tierId === 'endpoints');
	const slides = flattenEndpoints(endpoints.endpoints || []);

	return slides.find((_) => _.endpointId === endpointId);
}

/**
 * Get the endpoint records
 * @param {String} endpointId - endpointId to get the record for
 */
export function getAppManagerOverlay (endpointId) {
	const appManager = (store.getters.get_appManager || []);

	// Endpoints
	const endpoints = appManager.find((_) => _.tierId === 'overlays');
	const slides = flattenEndpoints(endpoints.endpoints || []);

	return slides.find((_) => _.endpointId === endpointId);
}

/**
 * Get Tier Endpoints
 * @param {String} tierId - tier Id to get the details for
 */
export function getAppManagerTier (tierId) {
	const appManager = (store.getters.get_appManager || []);

	// Endpoints
	const endpoints = appManager.find((_) => _.tierId === tierId);
	return flattenEndpoints(endpoints.endpoints || []);
}

export function flattenEndpoints (endpoints = []) {
	const _endpoints = [];

	endpoints.forEach((endpoint) => {
		_endpoints.push({
			...endpoint,
			...(endpoint.meta || {}),
			...(endpoint.parentTier || {})
		});
	});

	return _endpoints || [];
}
