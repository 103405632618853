import store from '../boot/store';

/**
 * Apply overlay interaction to buttons
 */
export function overlayInteraction () {
	const overlayButtons = document.querySelectorAll('[data-overlay]');

	overlayButtons.forEach((button) => {
		button.classList.add('is-ready');

		button.removeEventListener('click', null);
		button.addEventListener('click', () => {
			const overlay = button.getAttribute('data-overlay');
			store.dispatch('axn_updateOverlay', overlay);
		});
	})
}
