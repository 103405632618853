<template>
    <div
        class="swipe"
        @touchstart="initSwipe($event)"
        @touchend="endSwipe($event)"
        @mousedown="initClickSwipe($event)"
        @mouseup="endClickSwipe($event)"
    >
		<transition name="fade">
			<div
				v-if="overlay"
				class="swipe__background gaucher-app-background"
			/>
		</transition>

		<slot />
    </div>
</template>

<script>
	import { getAppManagerFlow } from '@core/lyra-data';
	import { goToSlide } from '@core/utils';

	export default {
		name: 'Swipe',
		data () {
			return {
				startX: 0,
				endX: 0,
				threshold: 100
			};
		},
		computed: {
			isSwipeDisabledState () {
				return this.$store.state.swipe.disabled;
			},
			flowId () {
				return this.$route.params.flowId;
			},
			endpointId () {
				return this.$route.params.endpointId;
			},
			overlay () {
				return this.$store.getters.get_overlay;
			}
		},
		methods: {
			initSwipe (event) {
				if (this.isSwipeDisabledState || this.overlay) {
					console.warn('Ignoring attempt to swipe due to scroll event.');
					return;
				}
				this.startX = event.changedTouches[0] ? event.changedTouches[0].screenX : 0;
			},
			initClickSwipe (event) {
				if (this.isSwipeDisabledState || this.overlay) {
					console.warn('Ignoring attempt to swipe due to scroll event.');
					return;
				}
				this.startX = event ? event.screenX : 0;
			},
			endSwipe (event) {
				if (this.isSwipeDisabledState || this.overlay) {
					console.warn('Ignoring attempt to swipe due to scroll event.');
					return;
				}
				this.endX = event.changedTouches[0] ? event.changedTouches[0].screenX : 1;

				this.evaluateSwipe();
			},
			endClickSwipe (event) {
				if (this.isSwipeDisabledState || this.overlay) {
					console.warn('Ignoring attempt to swipe due to scroll event.');
					return;
				}
				this.endX = event ? event.screenX : 1;

				this.evaluateSwipe();
			},
			evaluateSwipe () {
				const { startX, endX, threshold } = this;

				if (endX > startX && (endX - startX) > threshold) {
					this.swipeTo(-1);
				} else if (startX > endX && (startX - endX) > threshold) {
					this.swipeTo(1);
				}
			},
			/**
			 * Swipe to in direction
			 */
			swipeTo (direction) {
				const { route, slideId } = this.getNavigateToPath(direction);

				// Navigation
				if (route && this.$router.currentRoute.path !== route) {
					/**
					 * TODO: Veeva Navigation
					 */

					goToSlide({ slideId }, () => {
						this.$router.push(route);
					});
				}
			},

			/**
			 * Derive slide navigation data
			 */
			getNavigateToPath (direction) {
				const { flowId, endpointId } = this;
				const slides = getAppManagerFlow();

				const currentIndex = slides.findIndex((_) => _.endpointId === endpointId && _.flows === flowId);

				/**
				 * Back to home navigation
				 */
				if (currentIndex === 0 && direction === -1) { return; }
				if (currentIndex === (slides.length - 1) && direction === 1) { return; }

				const returnRoute = this.getSlidePath(slides[currentIndex + direction]);
				return returnRoute;
			},

			/**
			 * Get slide path
			 */
			getSlidePath (obj) {
				return {
					route: `/slide/${obj.flows}/${obj.endpointId}`,
					slideId: `${obj.flows}_${obj.endpointId}`,
				};
			}
		}
	};
</script>

<style lang="scss" scoped>
	.swipe {
		display: flex;
		width: 100%;
		height: calc(100vh - #{$footer-height});
		position: relative;

		&__background {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: calc(100vh - #{$footer-height});
			filter: blur($app-blur);

			z-index: calc(#{$z-app-background} + 1);
		}
	}
</style>
