<template>
	<div class="gaucher-bespoke-140_00_00">
        <h2
            class="patient__title"
            v-html="$ly(`140_00_00.patient.title`)"
        />
        <h3
            class="patient__subtitle"
            v-html="$ly(`140_00_00.title.subtitle`)"
        />
        <div class="content-header">
            <div class="content-header--tabs">
                <div
                    v-for="tab in tabs"
                    :key="tab"
                    class="tab"
                    :class="{ 'is-active': tabIsActive(tab) }"
                    :ref="tab"
                    v-html="$ly(`140_00_00.${tab}.label`)"
                    @click="activateTab(tab)"
                />

            </div>
        </div>

        <div class="content-copy">
            <div
                class="tab-content"
                v-if="tabActive === 'tab1'"
                v-html="$ly('140_00_00.tab1.copy')"
            />
            <div
                class="tab-content"
                v-if="tabActive === 'tab2'"
            >
                <div v-html="$ly('140_00_00.tab2.copy')" />
                <accordion :items="accordionItems"/>
            </div>
            <div
                class="tab-content"
                v-if="tabActive === 'tab3'"
                v-html="$ly('140_00_00.tab3.copy')"
            />
            <div
                class="tab-content tab4"
                v-if="tabActive === 'tab4'"
                v-html="$ly('140_00_00.tab4.copy')"
            />
        </div>
	</div>
</template>
<script>
    import Accordion from '@atoms/Accordion.vue';

	export default {
        name: 'Bespoke140_00_00',
        components: {
            Accordion
        },
        data() {
            return {
                tabs: ['tab1', 'tab2', 'tab3', 'tab4','tab5'],
                tabActive: 'tab1',
                accordionItems: [
                    {
                        title: this.$ly('140_00_00.tab2.accordion1.title'),
                        contentLeft: this.$ly('140_00_00.tab2.accordion1.content.left'),
                        contentRight: this.$ly('140_00_00.tab2.accordion1.content.right')
                    },
                    {
                        title: this.$ly('140_00_00.tab2.accordion2.title'),
                        contentLeft: this.$ly('140_00_00.tab2.accordion2.content.left'),
                        contentRight: this.$ly('140_00_00.tab2.accordion2.content.right')
                    },
                    {
                        title: this.$ly('140_00_00.tab2.accordion3.title'),
                        contentLeft: this.$ly('140_00_00.tab2.accordion3.content.left'),
                        contentRight: this.$ly('140_00_00.tab2.accordion3.content.right')
                    },
                    {
                        title: this.$ly('140_00_00.tab2.accordion4.title'),
                        contentLeft: this.$ly('140_00_00.tab2.accordion4.content.left'),
                        contentRight: this.$ly('140_00_00.tab2.accordion4.content.right')
                    },
                    {
                        title: this.$ly('140_00_00.tab2.accordion5.title'),
                        contentLeft: this.$ly('140_00_00.tab2.accordion5.content.left'),
                        contentRight: this.$ly('140_00_00.tab2.accordion5.content.right')
                    }
                ]
            }
        },
		methods: {
            activateTab (tab) {
                if (tab === 'tab5') {
                    this.$store.dispatch('axn_updateOverlay', '350_10_00');
                } else {
                    this.tabActive = tab;
                }
            },
            tabIsActive (tab) {
                return tab === this.tabActive;
            },
		}
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-140_00_00 {
        height: 100%;
        width: 596px;
        margin-top: 60px;
        margin-left: auto;
        .patient {
            &__title {
                font-size: 30px;
                line-height: 36px;
                @include FontMedium();
                width: 75%;
                margin-top: -77px;
            }
            &__subtitle {
                font-size: 16px;
                line-height: 30px;
                margin-top: -10px;
                margin-bottom: 10px;
            }
        }
		.content-header {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 15px;
           &--tabs {
               display: flex;
               border-bottom: 1px solid $red;
               .tab {
                   background: $white;
                   padding: 12px 12px 2px 12px;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   margin-right: 10px;
                   text-align: center;
                   border-bottom: solid 10px $white;
                   ::v-deep p {
                    font-size: 14px;
                    line-height: 18px;
                    color: $default-grey;
                    @include FontDemi();
                   }
               }
               .tab.is-active {
                   box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
                   border-bottom: solid 10px $red;
               }
               .tab:last-child {
                   width: 90px;
                   background-color: $tab-grey;
                   border-bottom: solid 10px $tab-grey;
                   margin-right: 0px;
               }
           }
        }
        .content-copy {
            display: flex;
            justify-content: flex-end;
            ::v-deep .tab-content {
                p {
                    font-size: 16px;
                    margin-bottom: 10px;
                    margin-top: -10px;
                }
            }
            ::v-deep .tab-content.tab4 {
				ul li {
					font-size: 13px;
					line-height: 18px;

					&:before {
						top: -11px;
					}
				}
                table {
                    width: 100%;
                    border: 1px solid #d5d7d9
                }
                thead th {
                    background-color: #6E6E91;
                    color: $white;
                    padding: 0.25em 1em;
                    font-size: 12px;
                }
                tr td {
                    text-align: center;
                    border: 1px solid #d5d7d9;
                    padding: 0.1em 1em;
					font-size: 0.8em;
                }
                tr:nth-child(odd) {
                    background-color: $white;
                }
                tr:nth-child(even) {
                    background-color: #F5F7FB;
                }
                tr td:first-child,
                tr th:first-child {
                    text-align: left;
                }
            }

        }
	}
</style>
