<template>
	<div
		class="gaucher-overlay-template"
		:class="[{ 'is-inactive': !overlay }, `overlay-${overlay.toLowerCase()}`]"
	>
		<div
			v-if="overlay"
			class="gaucher-overlay-template__base"
			:class="{ 'is-resources': overlay === 'resources-inactive' }"
		/>

		<transition name="overlayTitle">
			<div
				v-if="overlay"
				class="gaucher-overlay-template__title"
				:class="{ 'is-hidden': noTitle }"
				v-html="$ly(`${overlay.toLowerCase()}.title`)"
			/>
		</transition>

		<div
			v-if="overlay"
			class="gaucher-overlay-template__content"
			:class="{ 'is-resources': overlay === 'resources-inactive' }"
		>
			<div
				class="gaucher-overlay-template__close"
				@click="closeOverlay()"
			>
				<svg-icon :icon="icon" />
			</div>

			<transition name="fade">
				<core-template-library
					:overlay="overlay"
					@loaded="dataLoaded"
				/>
			</transition>
		</div>
	</div>
</template>

<script>
import CoreTemplateLibrary from './CoreTemplateLibrary';
import SvgIcon from '../atoms/SvgIcon';

export default {
	components: {
		CoreTemplateLibrary,
		SvgIcon,
	},
	data () {
		return {
			noTitle: false,
		}
	},
	computed: {
		overlay () {
			return this.$store.getters.get_overlay;
		},
		icon () {
			return this.overlay !== '480_10_00' ? 'close-grey' : 'close-white';
		}
	},
	methods: {
		closeOverlay () {
			this.$store.dispatch('axn_closeOverlay', '');
		},
		dataLoaded (obj) {
			this.noTitle = (obj && obj.notitle === 'true')
		}
	},
}
</script>

<style lang="scss" scoped>

@mixin dimensions {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: calc(100vh - #{$footer-height});
	display: flex;
	flex-direction: column;
}

.gaucher-overlay-template {
	@include dimensions();

	&.is-inactive {
		pointer-events: none;
	}

	&__base {
		@include dimensions();
		background: rgba($overlay-grey, .75);
		transition: all .3s ease;

		&.is-resources {
			background: $white;
		}
	}

	&__title {
		position: relative;
		z-index: 1;
		@include grid('width', 11);
		@include grid('padding-left', 1.15);
		margin: {
			bottom: em(19);
		}
		color: $blue;

		/deep/ p {
			@include FontBold();
			line-height: em(18);
			font-size: em(34);
			font-weight: bold;

			sup {
				&.sup-reg {
					font-size: em(10);
				}
			}
		}

		&.is-hidden {
			opacity: 0;
		}
		@include overlayTitleSize();
	}

	&__content {
		position: relative;
		z-index: 1;
		padding: em(14) em(16);
		background: $white;
		@include grid('margin-left', 1);
		@include grid('margin-right', 1);
		margin-bottom: em(62);
		flex: 2;
		box-shadow: 0 20px 100px 0 rgba(0,0,0,0.1);
		transition: all .3s ease;

		&.is-resources {
			box-shadow: none;
		}

		@include overlaySize();
	}

	&__close {
		width: 71px;
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: {
			left: auto;
		}

		.gaucher-icon {
			width: 71px;
			height: 72px;
			display: flex;
			align-items: center;
			justify-content: center;

			/deep/ svg {
				width: 71px;
				height: 72px;
			}
		}
	}
}

</style>
