<template>
	<!-- Template -->
	<div
		v-if="ready"
		class="gaucher-template"
		:class="{ 'is-ready': ready }"
	>

		<!-- Template Row -->
		<div
			v-for="item in templateRows"
			:key="`${endpointId}-${item}`"
			class="gaucher-row"
			:class="`gaucher-row__${endpointId}`"
		>
			<!-- Template Cols -->
			<template-area
				v-for="(col, index) in templateGrammar[item]"
				:key="`${endpointId}-${index}`"
				:datum="col"
				class="gaucher-col"
				:overlay="overlay"
			/>
		</div>
	</div>
</template>

<script>
	import TemplateArea from './builder/TemplateArea';
	import { getAppManagerEndpoint, getAppManagerOverlay } from '@core/lyra-data';

	export default {
		components: {
			TemplateArea,
		},
		props: {
			overlay: {
				type: String,
				default: '',
			}
		},
		data () {
			return {
				template: null,
				templateGrammar: null,
				ready: false,
			}
		},
		computed: {
			flowId () {
				return this.$route.params.flowId;
			},
			endpointId () {
				return this.$route.params.endpointId;
			},
			templateRows () {
				return this.templateGrammar ? Object.keys(this.templateGrammar) : [];
			}
		},
		watch: {
			async overlay () {
				this.ready = false;
				await this.queryData();
			}
		},
		async beforeMount () {
			await this.queryData();
		},
		methods: {
			/**
			 * Query grammar data for template
			 */
			async queryData () {
				const { endpointId, overlay } = this;

				let endpointObj = getAppManagerEndpoint(endpointId);
				if (overlay) {
					endpointObj = getAppManagerOverlay(overlay);
				}
				this.$emit('loaded', endpointObj);

				const { template } = endpointObj;
				this.template = template;

				if (overlay && template) {
					this.$store.dispatch('axn_updateOverlayTemplate', template);
				}

				const response = await this.$lyra.endpoints({
					tierId: 'template',
					endpointId: template,
				});
				if (!response.success || response.data.length < 1) { return; }

				const { data } = response;
				const [record] = data;

				const { datasets } = record;
				const [datum] = datasets;

				const { grammar } = datum;
				this.templateGrammar = (grammar && grammar.properties) ? grammar.properties : {};

				this.ready = true;
			}
		},
	};
</script>


<style lang="scss">
	@import '../../../design-system/styles/partials/modules/templates';
</style>

