<template>
	<div class="gaucher-bespoke-480_00_00">
		<div
            class="gaucher-bespoke-480_00_00__content"
            v-html="$ly('480_00_00.copy')"
        >

		</div>
         <div class="compare-btn" data-overlay="480_10_00">View comparison</div>
	</div>
</template>

<script>

	export default {
        name: 'Bespoke480_00_00',
	};

</script>

<style lang="scss" scoped>
	.gaucher-bespoke-480_00_00 {
		height: 100%;
		display: flex;
        justify-content: center;
        margin-top: 20px;

		&__content {
            display: flex;
            width: 100%;
            justify-content: space-between;
            ::v-deep .info-box {
                width: 439px;
                background: $white;
                &--title {
                    background: $red;
                    color: $white;
                    text-align: center;
                    font-size: 17px;
                    line-height: 26px;
                    padding: 10px 0;
                    @include FontDemi();
                    margin-bottom: 15px;
                }
                &--content {
                    text-align: center;
                    padding: 15px 10px;
                }
                &--image-holder {
                    display: flex;
                    min-height: 80px;
                    .image-holder-image.left {
                        background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/svg/blood-transfusion.svg');
                        width: 66px;
                        height: 73px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-left: 25px;
                        margin-right: 6px;
                    }
                    .image-holder-image.right {
                        background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/svg/pills.svg');
                        width: 48px;
                        height: 57px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-left: 12px;
                        margin-right: 25px;
                    }
                    .image-holder-content {
                        width: 100%;
                        text-align: center;
                        .content-row {
                            display: flex;
                            align-items: center;
                            margin-bottom: 15px;
                        }
                        .content-row.infobox-right {
                            justify-content: flex-end;
                        }
                        .grey-right-arrow {
                            width: 25px;
                            height: 10px;
                            margin: 0 8px;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background: url('../../../../design-system/assets/../shared/core/media/../shared/core/img/svg/grey-arrow-right.svg');
                        }
                        .purple-box {
                            background: #6E6E91;
                            border-radius: 2px;
                            color: $white;
                            font-size: 14px;
                            line-height: 26px;
                            text-align: center;
                            width: 145px;
                        }
                        .grey-box {
                            background: #D6D7D9;
                            border-radius: 2px;
                            color: #666666;
                            font-size: 14px;
                            line-height: 26px;
                            text-align: center;
                            width: 85px;
                        }
                    }
                }
                &--content p {
                    font-size: em(16);
                    line-height: em(22);
                }

            }
		}
        .compare-btn {
            background-color: $white;
            padding: 12px 30px 10px;
            border-radius: 22px;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
            text-transform: uppercase;
            font-size: em(13);
            line-height: em(13);
            @include FontDemi();
            display: inline-block;
            position:absolute;
            bottom: 8px;
            right: 404px;
        }
	}
</style>
