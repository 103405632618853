/**
 * Citations functions
 *
 * Author: Sahil David
 */

const { apiRequest, noKeyFound } = require('../utils');
const { clientLocalisation } = require('../helpers');
const { cachedData } = require('../cached/utils');
const { citationAPI, citationsAllAPI } = require('../../config/endpoints');

 /**
  * Returns citation value based on the key
  * @param {Object} params - connection params
  * @param {String} key - citation key to get value for
  */
async function $lyraCitation(params, key) {
	if (!key) { return noKeyFound(key); }

	const cacheResponse = await cachedData('lyraCitation', { key });
	if (cacheResponse.exit) {
		return cacheResponse.success ? cacheResponse.data : noKeyFound(key);
	}

	const url = citationAPI({
		':localisation': clientLocalisation(),
		':citationKey': key,
	}, { domain: params.domain });

	const _response = await apiRequest(url, params);
	return _response.success ? _response.data : noKeyFound(key);
}

/**
  * Checks if the citation key exist
  * @param {Object} params - connection params
  * @param {String} key - citation key to get value for
  */
 async function $lyraCitationExist(params, key) {
	if (!key) { return false; }

	const cacheResponse = await cachedData('lyraCitation', { key });
	if (cacheResponse.exit) {
		return cacheResponse.success ? true : false;
	}

	const url = citationAPI({
		':localisation': clientLocalisation(),
		':citationKey': key,
	}, { domain: params.domain });

	const _response = await apiRequest(url, params);
	return _response.success;
}

/**
  * Returns all citations
  * @param {Object} params - connection params
  * @param {String} key - citation key to get value for
  */
 async function $lyraCitationsAll(params, type) {
	const cacheResponse = await cachedData('lyraCitationsAll');
	if (cacheResponse.exit) {
		return cacheResponse.success ? cacheResponse.data : [];
	}

	const url = citationsAllAPI({
		':localisation': clientLocalisation(),
		':citationType': type,
	}, { domain: params.domain });

	const _response = await apiRequest(url, params);
	return _response.success ? _response.data : [];
}

/**
 * Export Functions
 */
module.exports.$lyraCitation = $lyraCitation;
module.exports.$lyraCitationExist = $lyraCitationExist;
module.exports.$lyraCitationsAll = $lyraCitationsAll;
