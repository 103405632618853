/**
 * Footer module for Vuex Store
 */

export default {
    state: {
		activePanel: '',
	},
	getters: {
		get_activePanel (stateObj) {
			return stateObj.activePanel;
		},
	},
	mutations: {
		updateActivePanel (stateObj, activePanel) {
			stateObj.activePanel = activePanel;
		},
	},
	actions: {
		axn_updateActivePanel (context, activePanel) {
			context.commit('updateActivePanel', activePanel);
		},
	}
};
